import { z } from 'zod';
import { zodDate, zodPosiNumber } from './ZodType';
import { StrgCate, VhGrpTy, AIGrpTy } from './Tms';

export enum MkFundUseTy {
  Disuse = 'Disuse',
  Use = 'Use',
  UseChartNotSelected = 'UseChartNotSelected',
  UseChartSelected = 'UseChartSelected',
}

export enum FundTy {
  None = 'None',
  Hedge = 'Hedge',
  Venture = 'Venture',
  ETF = 'ETF',
}

export interface MkFundMst {
  Id: string;
  nm: string;
  nm2: string;
  initd: string;
  endd: string;
  ivmnmraw?: string | null;
  ivmnm?: string | null;
  ivmId?: string | null;
  brkId?: string | null;
  useTy: MkFundUseTy;
  fundTy: FundTy;
  ord: number;
  newfundId?: string | null;
  chd: string;
  candi: boolean;
}

export const mkFundUseTySchema = z.nativeEnum(MkFundUseTy);
export const fundTySchema = z.nativeEnum(FundTy);
export const mkFundMstSchema = z.object({
  Id: z.string(),
  nm: z.string(),
  nm2: z.string(),
  fundTy: fundTySchema,
  initd: z.string(),
  brkId: z.string().nullish(),
  ivmId: z.string().nullish(),
  useTy: mkFundUseTySchema.nullish(),
  ord: zodPosiNumber(false), // numericStringSchema(false),
  newfundId: z.string().nullish(),
  chd: z.string().nullish(),
});

export interface MkIvm {
  Id: string;
  nm: string;
  nm2: string;
  ord?: number | null;
}

export const mkIvmSchema = z.object({
  Id: z.string(),
  nm: z.string(),
  nm2: z.string(),
  ord: zodPosiNumber(true), // numericStringSchema(true), // double
});

export interface MkFundDaily {
  Id: number;
  d: string;
  fundId?: string | null;
  fundnm?: string | null;
  ivmnm?: string | null;
  ivmnm2?: string | null;
  nav: number;
  b: number;
  rt: number;
  taxnav?: number | null;
  na?: number | null;
  prin: number;
}

export const mkFundDailySchema = z.object({
  d: zodDate,
  fundId: z.string(),
  fundnm: z.string().nullish().readonly(),
  ivmnm: z.string().nullish().readonly(),
  ivmnm2: z.string().nullish().readonly(),
  nav: zodPosiNumber(false), // numericStringSchema(false),
  b: zodPosiNumber(false), //  numericStringSchema(false),
});

export interface FundRt {
  Id: string;
  nm: string;
  nav: number | null;
  na: number | null;
  d1rt: number | null;
  d5rt: number | null;
  m1rt: number | null;
  m3rt: number | null;
  m6rt: number | null;
  y1rt: number | null;
  ytdrt: number | null;
  ord: number | null;
  cate: StrgCate | null;
  grp: VhGrpTy | null;
  stId: string | null;
  teamId: string | null;
  aigrpty: AIGrpTy | null;
  islast: boolean | null;
  taxnav: number | null;
}
