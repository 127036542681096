import React, { useMemo } from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
} from '@tanstack/react-table';
import DataGrid from 'tmslib/src/table/DataGrid';
import { PosWeiTarget } from '../../../Tms/BO';
import { SimpleVhcl } from '../../../shared/VhclSelector';

interface Props {
  vh: SimpleVhcl | null;
  st: string;
  isEtf: boolean;
  pos: PosWeiTarget[];
  setProd: (prod: string) => void;
}

const weiPdfTemplate = (
  weiv1: number | undefined,
  pdfv2: number | undefined,
) => {
  if (weiv1 === undefined || pdfv2 === undefined) return null;
  return weiv1 - pdfv2;
};

const columnHelper = createColumnHelper<PosWeiTarget>();

const getColumns = (vh: SimpleVhcl | null, st: string, isEtf: boolean) => {
  const virtual = ['SMIC', 'IVY', 'VLS'].contains(vh?.Id ?? '');
  const singa = vh?.Id === 'SINGA';
  const whole = st === 'Whole';
  const columns = [
    columnHelper.accessor('sec', {
      header: 'Sec',
      size: virtual ? 80 : 50, // SMIC 등은 섹터명으로 보여줘서
      meta: { textAlign: 'center' },
    }),
    isEtf
      ? null
      : columnHelper.accessor('grp', {
        header: 'Grp',
        size: 80,
        meta: { textAlign: 'center' },
      }),
    isEtf
      ? columnHelper.accessor('etfSecNm', {
        header: '섹터',
        size: 80,
        meta: { textAlign: 'center' },
      })
      : null,
    columnHelper.accessor('prodIdRstr', {
      header: 'Code',
      size: 90,
      meta: { textAlign: 'center' },
    }),
    columnHelper.accessor('prodNmRstr', {
      header: 'Name',
      size: 200,
      meta: {
        textAlign: 'left',
      },
    }),
    isEtf
      ? null
      : columnHelper.accessor('wei', {
        header: 'Wei',
        meta: { formatter: (v) => v?.toFixed(1) },
      }),
    columnHelper.accessor('close', {
      header: 'Last',
      size: 60,
      meta: {
        formatter: (v) => v?.toFixedSigFig(0),
      },
    }),
    columnHelper.accessor('ret', {
      header: 'Ch%',
      meta: {
        formatter: (v) => v?.toFixed(1),
        styler: (v) => ({
          color: v?.getSignColor(),
        }),
      },
    }),
    columnHelper.accessor('pos', {
      header: 'Pos',
      size: 90,
      meta: {
        formatter: (v) => v?.toFixedWithComma(0),
      },
    }),
    virtual || (whole && !singa) // singa는 주문비중말고 포지션 체결 바로 보여주는거라 whole도 보여붐
      ? null
      : columnHelper.accessor('bPrgs', {
        header: 'Buy',
        size: 120,
      }),
    virtual || (whole && !singa)
      ? null
      : columnHelper.accessor('sPrgs', {
        header: 'Sell',
        size: 120,
      }),
    columnHelper.accessor('div', {
      header: 'Div',
    }),
    columnHelper.accessor('prftVhCcy', {
      header: `PL(${vh?.crncy === 'USD' ? 'K' : '백만'})`,
      size: 70,
      meta: {
        formatter: (v, r) =>
          (
            (v ?? r.original.prft) * (vh?.crncy === 'USD' ? 1e-3 : 1e-6)
          ).toFixedWithComma(1),
        styler: (v, r) => ({
          color: (r.original.prftVhCcy ?? r.original.prft).getSignColor(),
        }),
      },
    }),
    columnHelper.accessor('amtVhCcy', {
      header: `Amt(${singa ? 'K' : '백만'})`,
      size: 70,
      meta: {
        formatter: (v: number) =>
          (v * (singa ? 1e-3 : 1e-6))?.toFixedWithComma(1),
      },
    }),
    columnHelper.accessor('beta', {
      header: 'Beta',
    }),
    // TODO: 가상, SMIC 등은 여기서 PER, PBR, ROE
  ]
    .filter((v) => v)
    .map((v) => v as ColumnDef<PosWeiTarget, unknown>);

  if (!isEtf) return columns;

  const etfWeiColumns = [
    columnHelper.accessor('wei', {
      header: '실제',
      meta: {
        formatter: (v) => v?.toFixedWithComma(2),
        styler: () => ({ backgroundColor: 'aliceblue' }),
      },
    }),
    columnHelper.accessor((r) => r.etfPfWei?.Pdf, {
      header: 'Pdf',
      meta: { formatter: (v) => v?.toFixedWithComma(2) },
    }),
    columnHelper.accessor((r) => weiPdfTemplate(r.wei, r.etfPfWei?.Pdf), {
      // wei - Pdf
      header: '실제-Pdf',
      size: 65,
      meta: { formatter: (v) => v?.toFixedWithComma(2) },
    }),
    columnHelper.accessor((r) => r.etfPfWei?.PassivePf, {
      header: '지수추종',
      size: 65,
      meta: { formatter: (v) => v?.toFixedWithComma(2) },
    }),
    columnHelper.accessor((r) => r.etfPfWei?.TFL, {
      header: 'TFL',
      meta: { formatter: (v) => v?.toFixedWithComma(2) },
    }),
    columnHelper.accessor((r) => weiPdfTemplate(0, r.etfPfWei?.TFS), {
      // toNumber NaN
      header: '(short)',
      meta: { formatter: (v) => v?.toFixedWithComma(2) },
    }),
    columnHelper.accessor((r) => r.etfPfWei?.ETFL, {
      header: 'ETFL',
      meta: { formatter: (v) => v?.toFixedWithComma(2) },
    }),
    columnHelper.accessor((r) => r.etfPfWei?.ActivePf, {
      header: '액티브',
      meta: { formatter: (v) => v?.toFixedWithComma(2) },
    }),
  ].map((v) => v as ColumnDef<PosWeiTarget, unknown>);

  return [
    columnHelper.group({
      id: '_info',
      header: () => <span>기본</span>,
      columns,
    }),
    columnHelper.group({
      id: '_wei',
      header: () => <span>비중(%)</span>,
      columns: etfWeiColumns,
    }),
  ];
};

export default function PosWeiTab({ vh, st, isEtf, pos, setProd }: Props) {
  const meta: TableMeta<PosWeiTarget> = {
    showRowNum: true,
    onRowClick: (r) => {
      const row = r.original;
      setProd(row.prodId);
    },
  };

  const cols = useMemo(() => getColumns(vh, st, isEtf), [vh, st, isEtf]);
  console.log(pos)
  return <DataGrid data={pos} columns={cols} meta={meta} />;
}
