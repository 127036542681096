import React, { useEffect, useMemo, useState } from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
} from '@tanstack/react-table';
import _ from 'lodash';
import DataGrid, { ColumnType, DataGridState } from 'tmslib/src/table/DataGrid';
import { IContextMenuItem } from 'tmslib/src/table/ContextMenu';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import {
  LoanReqBrkType,
  LoanReqState,
  LoanReqQuotes,
  LoanReq,
  LoanFail,
} from '../../../Tms/Loan';
import { useAuthState } from '../../Auth/AuthContext';
import { PBSs, getCompName } from '../../../Tms/Identity';
import { callAxios, checkItems } from '../../../tmsutil';

interface Props {
  setUpdateNeeded: () => void;
  d: string;
  keep: boolean;
  cnfrmMin: number;
  reqs: LoanReq[];
  fails: LoanFail[];
  currId: number | undefined;
  setCurrId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const stateFormatter = (
  r: LoanReq,
  cnfrmMin: number,
  isHouse: boolean,
  isPbs: boolean,
  loanFailBrks: { [key: string]: string[] },
  swapFailBrks: { [key: string]: string[] },
): [string, React.CSSProperties | null] => {
  if ([LoanReqState.생성, LoanReqState.요청].contains(r.state)) {
    let failedBrks = loanFailBrks[r.prodId] ?? [];
    if (failedBrks.contains(r.brkId))
      return ['대차 불가', { backgroundColor: 'lightgray' }];
    if (r.swap) {
      failedBrks = swapFailBrks[r.prodId] ?? [];
      if (failedBrks.contains(r.brkId))
        return ['스왑 불가', { backgroundColor: 'lightgray' }];
    }
  }
  if (r.state === LoanReqState.요청) {
    const txt = `PBS컨펌 ${isPbs ? '필요' : '대기중'}`;
    const style = {
      color: isPbs ? 'red' : '#111',
      backgroundColor: isPbs ? 'yellow' : '#ccc',
    };
    return [txt, style];
  }
  if (r.state === LoanReqState.PBS컨펌) {
    let txt = `타임컨펌 ${isHouse ? '필요' : '대기중'}`; // => 타임컨펌필요 대신 최저값 검증 여부 (특히 풀 요율 올린경우)
    if (
      isHouse &&
      (r.quote === LoanReqQuotes.확정 || r.quote == null) &&
      (r.fee ?? 0) >= cnfrmMin &&
      r.isConfirmOK !== true
    ) {
      txt = '매니저컨펌 대기중';
    } else if (isHouse && r.swapInsuf) {
      txt = '수량 부족';
    }
    const style = {
      color: isHouse ? 'red' : '#111',
      backgroundColor: isHouse ? 'yellow' : '#ccc',
    };
    return [txt, style];
  }
  if (r.state === LoanReqState.타임컨펌) {
    if (r.swap) {
      const txt = `스왑세팅 ${isPbs ? '필요' : '대기중'}`;
      const style = {
        color: isPbs ? 'blue' : '#111',
        backgroundColor: isPbs ? 'yellow' : '#ccc',
      };
      return [txt, style];
    }
  }
  return [r.state.toString(), null];
};

const columnHelper = createColumnHelper<LoanReq>();

const getColumns = (
  keep: boolean,
  cnfrmMin: number,
  isHouse: boolean,
  isPbs: boolean,
  loanFailBrks: { [key: string]: string[] },
  swapFailBrks: { [key: string]: string[] },
) =>
  [
    columnHelper.accessor('ord', {
      header: '순번',
      size: 40,
      meta: { frozen: true },
    }),
    columnHelper.accessor('tgt', {
      header: '타겟',
      size: 40,
      meta: { frozen: true, formatter: (v) => v.substring(0, 1) },
    }),
    columnHelper.accessor('prodId', {
      header: '종목코드',
      size: 70,
      meta: { frozen: true },
    }),
    columnHelper.accessor('prodNm', {
      header: '종목명',
      size: 120,
      meta: { frozen: true },
    }),
    keep &&
      isHouse &&
      columnHelper.accessor('stId', {
        header: '전략',
        meta: { frozen: true },
      }),
    columnHelper.accessor('availq', {
      header: '가능량*',
      meta: { formatter: (v) => v?.toFixedWithComma(0) },
    }),
    columnHelper.accessor('fee', {
      header: '요율(%)*',
      meta: {
        formatter: (v) => v?.toFixed(2),
        styler: (v, r) =>
          isHouse && (r.original.fee ?? 0) >= cnfrmMin
            ? {
                backgroundColor: r.original.isConfirmOK
                  ? 'yellow'
                  : 'lightgray',
              }
            : null,
      },
    }),
    columnHelper.accessor('needq', {
      header: '필요량',
      meta: { frozen: true },
    }),
    columnHelper.accessor('finalq', {
      header: '확정량',
      meta: { frozen: true, styler: () => ({ fontWeight: 'bold' }) },
    }),
    columnHelper.accessor('swap', {
      header: '스왑',
      size: 35,
      meta: { frozen: true, type: ColumnType.Checkbox },
    }),
    columnHelper.accessor('brkTy', {
      header: '구분',
      size: 70,
      meta: {
        frozen: true,
        formatter: (v) => {
          if (isPbs) return v === LoanReqBrkType.Pool ? '개런티' : '';
          return v;
        },
      },
    }),
    isHouse &&
      columnHelper.accessor('brkId', {
        header: 'PBS',
        meta: { frozen: true },
      }),
    isHouse &&
      columnHelper.accessor('swapBrkFam', {
        header: '계열',
        meta: {
          frozen: true,
          styler: (v) =>
            v ? { color: 'red', backgroundColor: 'yellow' } : null,
        },
      }),
    isHouse &&
      columnHelper.accessor('quote', {
        header: '비교',
        meta: { frozen: true },
      }),
    isHouse &&
      columnHelper.accessor('noQuote', {
        header: '고정',
        size: 35,
        meta: { frozen: true, type: ColumnType.Checkbox },
      }),
    columnHelper.accessor('state', {
      header: '단계',
      size: 90,
      meta: {
        frozen: true,
        formatter: (v, r) =>
          stateFormatter(
            r.original,
            cnfrmMin,
            isHouse,
            isPbs,
            loanFailBrks,
            swapFailBrks,
          )[0],
        styler: (v, r) =>
          stateFormatter(
            r.original,
            cnfrmMin,
            isHouse,
            isPbs,
            loanFailBrks,
            swapFailBrks,
          )[1],
      },
    }),
    columnHelper.accessor('operNm', {
      header: '처리자',
      meta: { frozen: true },
    }),
    columnHelper.accessor('tstr', {
      header: '생성T',
      meta: { frozen: true },
    }),
    keep &&
      columnHelper.accessor('availd', {
        header: '가능일*',
        size: 80,
      }),
    isHouse &&
      columnHelper.accessor('stInfo', {
        header: '매니저컨펌',
        cell: (c) => {
          const info = c.getValue();
          if (!info || !info.length) return null;
          return (
            <>
              {info.map((r) => {
                const col = r.acpt === false ? 'white' : 'black';
                let bgcol = 'ghostwhite';
                if (r.acpt) bgcol = 'yellow';
                else if (r.acpt === false) bgcol = 'dimgray';
                return (
                  <span key={r.st}>
                    <span style={{ color: col, backgroundColor: bgcol }}>
                      {r.st}
                    </span>
                    &nbsp;
                  </span>
                );
              })}
            </>
          );
        },
        size: 80,
        meta: { frozen: true },
      }),
    isHouse &&
      columnHelper.accessor('error', {
        header: '에러',
        size: 80,
        meta: { frozen: true, styler: () => ({ color: 'red' }) },
      }),
  ]
    .filter((v) => v)
    .map((v) => v as ColumnDef<LoanReq, unknown>);

const updateReqStateAvail = (reqs: LoanReq[], state: LoanReqState) => {
  switch (state) {
    case LoanReqState.요청:
      return reqs.every((v) => v.state === LoanReqState.생성);
    case LoanReqState.취소:
      return reqs.every((v) =>
        [LoanReqState.생성, LoanReqState.요청, LoanReqState.PBS컨펌].contains(
          v.state,
        ),
      );
    case LoanReqState.타임컨펌:
      return reqs.every(
        (v) => v.state === LoanReqState.PBS컨펌 && (v.availq ?? 0) > 0,
      );
    case LoanReqState.PBS컨펌:
      return reqs.every(
        (v) =>
          v.state === LoanReqState.요청 &&
          (v.availq ?? 0) > 0 &&
          (v.fee ?? 0) > 0,
      );
    case LoanReqState.스왑세팅:
      return reqs.every((v) => v.state === LoanReqState.타임컨펌 && v.swap);
    default:
      return false;
  }
};

const isBrkAvail = (
  items: LoanReq[],
  brk: string,
  loanFailBrks: { [key: string]: string[] },
  swapFailBrks: { [key: string]: string[] },
) => {
  const fail = _.uniqWith(
    items.map((v) => ({ prodId: v.prodId, swap: v.swap })),
    _.isEqual,
  ).some((r) => {
    let failedBrks = loanFailBrks[r.prodId] ?? [];
    if (failedBrks.contains(brk)) return true;
    if (r.swap) {
      failedBrks = swapFailBrks[r.prodId] ?? [];
      if (failedBrks.contains(brk)) return true;
    }
    return false;
  });
  return !fail;
};

const enforceCancelAvail = (items: LoanReq[]) =>
  items.every(
    (v) =>
      ![LoanReqState.완료, LoanReqState.취소].contains(v.state) &&
      !(v.swap && v.state === LoanReqState.스왑세팅) &&
      !(!v.swap && v.state === LoanReqState.타임컨펌),
  );

export default function LoanReqTab({
  setUpdateNeeded,
  d,
  keep,
  cnfrmMin,
  reqs,
  fails,
  currId,
  setCurrId,
}: Props) {
  const [dgState, setDgState] = useState<DataGridState<LoanReq>>();

  useEffect(() => {
    if (dgState?.currentObj?.Id !== currId) {
      setCurrId(dgState?.currentObj?.Id);
    }
  }, [currId, setCurrId, dgState]);

  const loanFailBrks = _.chain(fails.filter((v) => !v.swap))
    .groupBy('prodId')
    .mapValues((g) => g.map((v) => v.brkId))
    .value();
  const swapFailBrks = _.chain(fails.filter((v) => v.swap))
    .groupBy('prodId')
    .mapValues((g) => g.map((v) => v.brkId))
    .value();

  const { user } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const isHouse = user?.isHouse ?? false;
  const isPbs = user?.isPbs ?? false;

  const call = (func: string, params: unknown, onSuccess?: () => void) =>
    callAxios({
      m,
      logger,
      url: `/PBS/LoanReq/${func}`,
      params,
      onSuccess: onSuccess ?? (() => setUpdateNeeded()),
    });

  const updateReqState = (items: LoanReq[], state: LoanReqState) => {
    if (!checkItems(items, m)) return;
    const par = { ids: items.map((v) => v.Id), val: state };
    call('UpdateReqState', par);
  };

  const updateReqBrkId = (items: LoanReq[], brk: string) => {
    if (!checkItems(items, m)) return;
    const par = { ids: items.map((v) => v.Id), brk };
    call('UpdateReqBrkId', par);
  };

  const enforceCancel = async (items: LoanReq[]) => {
    if (!checkItems(items, m)) return;
    if (!(await m.confirm(`${items.length}개 요청 강제 취소?`))) return;
    const par = { ids: items.map((v) => v.Id) };
    call('EnforceCancel', par);
  };

  const setNoQuote = (items: LoanReq[]) => {
    if (!checkItems(items, m)) return;
    const par = { ids: items.map((v) => v.Id) };
    call('SetNoQuote', par);
  };

  const setOperId = (items: LoanReq[]) => {
    if (!checkItems(items, m)) return;
    const par = { ids: items.map((v) => v.Id) };
    call('SetOperId', par);
  };

  const addToLoanFail = (items: LoanReq[], swap: boolean) => {
    if (!checkItems(items, m)) return;
    const brks = _.uniq(items.map((v) => v.brkId));
    if (brks.length > 1) {
      m.alert('복수 증권사');
      return;
    } // pbs화면서 호출할거라 불가한 케이스 (dev 아니면)
    const par = {
      d,
      brk: brks[0],
      swap,
      prods: _.uniq(items.map((v) => v.prodId)),
    };
    call('AddToLoanFail', par, () => {
      m.alert('대차 불가 설정 완료');
      setUpdateNeeded();
    });
  };

  const cxtMenus: IContextMenuItem<LoanReq>[] = [];
  if (isHouse) {
    cxtMenus.push(
      ...[LoanReqState.요청, LoanReqState.취소, LoanReqState.타임컨펌].map(
        (s) => ({
          label: s,
          callback: (items: LoanReq[]) => updateReqState(items, s),
          disabled: (items: LoanReq[]) => !updateReqStateAvail(items, s),
        }),
      ),
    );

    cxtMenus.push({ divider: true });

    cxtMenus.push(
      ...PBSs.map((c) => ({
        label: getCompName(c),
        callback: (items: LoanReq[]) => updateReqBrkId(items, c),
        disabled: (items: LoanReq[]) =>
          !isBrkAvail(items, c, loanFailBrks, swapFailBrks),
      })),
    );

    cxtMenus.push(
      ...[
        { divider: true },
        {
          label: '강제 취소',
          callback: (items: LoanReq[]) => enforceCancel(items),
          disabled: (items: LoanReq[]) => !enforceCancelAvail(items),
        },
        {
          label: '증권사 고정',
          callback: (items: LoanReq[]) => setNoQuote(items),
          disabled: (items: LoanReq[]) => items.some((v) => v.noQuote),
        },
      ],
    );

    if (user?.isDev) cxtMenus.push({ divider: true });

    if (user?.isPbs || user?.isDev) {
      cxtMenus.push(
        ...[
          {
            label: '처리자 설정',
            callback: (items: LoanReq[]) => setOperId(items),
          },
          {
            label: 'PBS 컨펌',
            callback: (items: LoanReq[]) =>
              updateReqState(items, LoanReqState.PBS컨펌),
            disabled: (items: LoanReq[]) =>
              !updateReqStateAvail(items, LoanReqState.PBS컨펌),
          },
          {
            label: '스왑 세팅 완료',
            callback: (items: LoanReq[]) =>
              updateReqState(items, LoanReqState.스왑세팅),
            disabled: (items: LoanReq[]) =>
              !updateReqStateAvail(items, LoanReqState.스왑세팅),
          },
          { divider: true },
          {
            label: '대차 불가',
            callback: (items: LoanReq[]) => addToLoanFail(items, false),
            disabled: (items: LoanReq[]) =>
              items.some((v) => v.state !== LoanReqState.요청),
          },
          {
            label: '스왑 세팅 불가',
            callback: (items: LoanReq[]) => addToLoanFail(items, true),
            disabled: (items: LoanReq[]) =>
              items.some((v) => !(v.state === LoanReqState.타임컨펌 && v.swap)),
          },
        ],
      );
    }
  }

  const meta: TableMeta<LoanReq> = {
    height: 1000,
    dftColWidth: 60,
    contextMenus: cxtMenus,
    editable: true,
  };

  const columns = useMemo(
    () =>
      getColumns(keep, cnfrmMin, isHouse, isPbs, loanFailBrks, swapFailBrks),
    // console.log('recalc col')
    [keep, cnfrmMin, isHouse, isPbs, fails],
  );

  return (
    <DataGrid
      data={reqs}
      columns={columns}
      meta={meta}
      onStateChange={setDgState}
    />
  );
}
