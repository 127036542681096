import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { callAxiosGet } from '../../../tmsutil';

interface Props {
  currAlgoId: number | undefined;
}

export default function AlgoPrgsChart({ currAlgoId }: Props) {
  const [AlgoPrgsOpts, setAlgoPrgsOpts] = useState<Highcharts.Options>({
    chart: { type: 'line', width: 400, height: 200 },
    credits: { enabled: false },
    title: { text: '', margin: 0, style: { fontSize: '14px' } },
    xAxis: { type: 'datetime' },
    yAxis: { title: { text: null } },
    legend: { enabled: false },
    plotOptions: {
      line: {
        marker: { enabled: false },
        shadow: false,
        animation: false,
        lineWidth: 2,
      },
    },
    accessibility: { enabled: false },
  });

  const { msgBox: m, logger } = useMessageState();

  useEffect(() => {
    if (currAlgoId === undefined) {
      return;
    }
    const params = { id: currAlgoId };
    callAxiosGet({
      m,
      logger,
      url: '/Back/OrdProc/GetAlgoPrgsData',
      params,
      onSuccess: (data) => {
        setAlgoPrgsOpts((p) => ({
          ...p,
          series: [{ data: data.turnOver, name: '', type: 'line' }],
        }));
      },
    });
  }, [currAlgoId]);

  return <HighchartsReact highcharts={Highcharts} options={AlgoPrgsOpts} />;
}
