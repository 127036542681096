import React from 'react';
import Test from 'tmslib/src/Test';
import Home from './pages/Home';
import Logout from './pages/Auth/Logout';

import Summary from './pages/Fund/Summary';
import PortfolioMain from './pages/Fund/Portfolio/PortfolioMain';
import Liquidity from './pages/Fund/Liquidity';
import FoHFs from './pages/Fund/FoHFs';
import RtPrd from './pages/Fund/RtPrd';

import Allocation from './pages/Master/Allocation';

// Front
import TargetMain from './pages/Front/Target/TargetMain';
import PfAnal from './pages/Front/PfAnal';
import VhDiff from './pages/Front/VhDiff';
import WeeklyPlan from './pages/Front/WeeklyPlan';
import LsWeekly from './pages/Front/LsWeekly';
import GoogleTrend from './pages/Front/GoogleTrend';
import LsUniv from './pages/Front/LsUniv';
import LsPick from './pages/Front/LsPick';
import LsPickUS from './pages/Front/LsPickUS';
import LsPerf from './pages/Front/LsPerf';

// Middle
import Review from './pages/Middle/Review';
import Ownership from './pages/Middle/Ownership';
import Conflict from './pages/Middle/Conflict';
import Unfair from './pages/Middle/Unfair';
import PreAlloc from './pages/Middle/PreAlloc';
import ShortCheck from './pages/Middle/ShortCheck';

import Permit from './pages/Middle/Permit';
import RiskMaster from './pages/Middle/RiskMaster';
import RiskConfig from './pages/Middle/RiskConfig';
import VaR from './pages/Middle/VaR';

import CheckList from './pages/Middle/CheckList';
import CheckReport from './pages/Middle/CheckReport';

// Back
import OrdProcMain from './pages/Back/OrdProc/OrdProcMain';
import AlgoSpot from './pages/Back/AlgoSpot';
import Diff from './pages/Back/Diff';
import TrdCtrl from './pages/Back/TrdCtrl';
import DebtAvail from './pages/Back/DebtAvail';
import FundMgt from './pages/Back/FundMgt';
import Closing from './pages/Back/Closing';
import RstrPosMain from './pages/Back/RstrPos';
import CronJobMain from './pages/Back/CronJob';
import Master from './pages/Back/Master';
import Market from './pages/Back/Market';
import Query from './pages/Back/Query';

// AI
import BookView from './pages/AI/BookView';
import BookData from './pages/AI/BookData';

// ETF
import RetAttrib from './pages/ETF/RetAttrib';

// Tools
import RoomBookMain from './pages/Tools/RoomBook';

// PBS
import LoanReqMain from './pages/PBS/LoanReq/LoanReqMain';
import LoanOfferReqMain from './pages/PBS/LoanOfferReq/LoanOfferReqMain';
import Swap from './pages/PBS/Swap';

// SINGA
import Back from './pages/Singa/Back';

// Admin
import Users from './pages/Admin/Users';
import VhStAuthPage from './pages/Admin/VhStAuth';
import MenuAuth from './pages/Admin/MenuAuth';
import HealthCheck from './pages/Admin/HealthCheck';

import EtfData from './pages/ETF/EtfData';
import CalendarPage from './pages/Tools/CalendarPage';
import IPMS from './pages/Back/IPMS';
import GenPdf from './pages/ETF/GenPdf';
import { CalendarType } from './Tms/Tools';
import Mezzanine from './pages/AI/Mezzanine';
// import Chatbot from './pages/Chat/Chatbot';

const getAppRoutes = () => [
  {
    index: true,
    path: '/',
    element: <Home />,
  },
  {
    path: '/Fund/Summary',
    element: <Summary />,
  },
  {
    path: '/Fund/Portfolio',
    element: <PortfolioMain />,
  },
  {
    path: '/Fund/Liquidity',
    element: <Liquidity />,
  },
  {
    path: '/Fund/FoHFs',
    element: <FoHFs />,
  },
  {
    path: '/Fund/RtPrd',
    element: <RtPrd />,
  },
  {
    path: '/Master/Allocation',
    element: <Allocation />,
  },

  // Front
  {
    path: '/Front/Target',
    element: <TargetMain />,
  },
  {
    path: '/Front/PfAnal',
    element: <PfAnal />,
  },
  {
    path: '/Front/VhDiff',
    element: <VhDiff />,
  },
  {
    path: '/Front/WeeklyPlan',
    element: <WeeklyPlan />,
  },
  {
    path: '/Front/LsWeekly',
    element: <LsWeekly />,
  },
  {
    path: '/Front/GoogleTrend',
    element: <GoogleTrend />,
  },
  {
    path: '/Front/LsUniv',
    element: <LsUniv />,
  },
  {
    path: '/Front/LsPick',
    element: <LsPick />,
  },
  {
    path: '/Front/LsPickUS',
    element: <LsPickUS />,
  },
  {
    path: '/Front/LsPerf',
    element: <LsPerf />,
  },
  // Middle
  {
    path: '/Middle/Review',
    element: <Review />,
  },
  {
    path: '/Middle/Ownership',
    element: <Ownership />,
  },
  {
    path: '/Middle/Conflict',
    element: <Conflict />,
  },
  {
    path: '/Middle/Unfair',
    element: <Unfair />,
  },
  {
    path: '/Middle/PreAlloc',
    element: <PreAlloc />,
  },
  {
    path: '/Middle/ShortCheck',
    element: <ShortCheck />,
  },

  {
    path: '/Middle/Permit',
    element: <Permit />,
  },
  {
    path: '/Middle/RiskMaster',
    element: <RiskMaster />,
  },
  {
    path: '/Middle/RiskConfig',
    element: <RiskConfig />,
  },
  {
    path: '/Middle/VaR',
    element: <VaR />,
  },
  {
    path: '/Middle/AiCal',
    element: <CalendarPage ty={CalendarType.CmplAi} />,
  },
  {
    path: '/Middle/CheckList',
    element: <CheckList />,
  },
  {
    path: '/Middle/CheckReport',
    element: <CheckReport />,
  },
  // Back
  {
    path: '/Back/OrdProc',
    element: <OrdProcMain />,
  },
  {
    path: '/Back/AlgoSpot',
    element: <AlgoSpot />,
  },
  {
    path: '/Back/Diff',
    element: <Diff />,
  },
  {
    path: '/Back/TrdCtrl',
    element: <TrdCtrl />,
  },
  {
    path: '/Back/DebtAvail',
    element: <DebtAvail />,
  },
  {
    path: '/Back/FundMgt',
    element: <FundMgt />,
  },
  {
    path: '/Back/Closing',
    element: <Closing />,
  },
  {
    path: '/Back/RstrPos',
    element: <RstrPosMain />,
  },
  {
    path: '/Back/CronJob',
    element: <CronJobMain />,
  },
  {
    path: '/Back/Master',
    element: <Master />,
  },
  {
    path: '/Back/Market',
    element: <Market />,
  },
  {
    path: '/Back/Query',
    element: <Query />,
  },
  {
    path: '/Back/IPMS',
    element: <IPMS />,
  },
  // AI
  {
    path: '/AI/Mezzanine',
    element: <Mezzanine />,
  },
  {
    path: '/AI/BookView',
    element: <BookView />,
  },
  {
    path: '/AI/BookData',
    element: <BookData />,
  },

  // ETF
  {
    path: '/ETF/GenPdf',
    element: <GenPdf />,
  },
  {
    path: '/ETF/Data',
    element: <EtfData />,
  },
  {
    path: '/ETF/RetAttrib',
    element: <RetAttrib />,
  },

  // Tools
  {
    path: '/Tools/RoomBook',
    element: <RoomBookMain />,
  },
  {
    path: '/Tools/Seminar',
    element: <CalendarPage ty={CalendarType.Seminar} />,
  },
  {
    path: '/Tools/SeminarSinga',
    element: <CalendarPage ty={CalendarType.SeminarSinga} />,
  },
  {
    path: '/Tools/IPO',
    element: <CalendarPage ty={CalendarType.IPO} />,
  },
  // PBS
  {
    path: '/PBS/LoanReq',
    element: <LoanReqMain />,
  },
  {
    path: '/PBS/LoanOfferReq',
    element: <LoanOfferReqMain />,
  },
  {
    path: '/PBS/Swap',
    element: <Swap />,
  },

  // Singa
  {
    path: '/Singa/Back',
    element: <Back />,
  },

  // Admin
  {
    path: '/Admin/Users',
    element: <Users />,
  },
  {
    path: '/Admin/MenuAuth',
    element: <MenuAuth />,
  },
  {
    path: '/Admin/VhStAuth',
    element: <VhStAuthPage />,
  },
  {
    path: '/Admin/HealthCheck',
    element: <HealthCheck />,
  },

  // {
  //   path: '/Chat/Chatbot',
  //   element: <Chatbot />,
  // },
  {
    path: '/logout',
    element: <Logout />,
  },
  {
    path: '/test',
    element: <Test />,
  },
];

export default getAppRoutes;
