// https://velog.io/@yaytomato/%ED%94%84%EB%A1%A0%ED%8A%B8%EC%97%90%EC%84%9C-%EC%95%88%EC%A0%84%ED%95%98%EA%B2%8C-%EB%A1%9C%EA%B7%B8%EC%9D%B8-%EC%B2%98%EB%A6%AC%ED%95%98%EA%B8%B0
import React, { useState } from 'react';
import './Login.css';
import {
  Logger,
  MessageBox,
  useMessageState,
} from 'tmslib/src/context/MessageContext';
import Dialog from 'tmslib/src/ui/Dialog';
import Button from 'tmslib/src/ui/Button';
import { getCookie } from '../cookie';
import { FormEventTy } from '../../AppTypes';
import { useAuthDispatch, JWT_EXPIRY_TIME, AuthAction } from './AuthContext';
import { callAxios } from '../../tmsutil';

export const onSilentRefresh = (
  m: MessageBox,
  logger: Logger,
  dispatch: React.Dispatch<AuthAction>,
  tmpuserid?: string
) => {
  const userid = tmpuserid ?? getCookie('userid');
  if (!userid) {
    m.alert('로그인 유지 실패. 재로그인하세요');
    return;
  }
  const params = { userid };
  callAxios({
    m,
    logger,
    url: '/Auth/SilentRefresh',
    params,
    onSuccess: (data) => {
      dispatch({
        type: 'SILENT_REFRESH',
        auth: data.auth,
        info: data.info,
      });
      setTimeout(
        () => onSilentRefresh(m, logger, dispatch),
        JWT_EXPIRY_TIME - 60 * 1000,
      );
    },
    onError: (errMsgs) => {
      dispatch({
        type: 'SILENT_REFRESH_ERROR',
        errMsg: errMsgs.join('\n'),
      });
    },
  });
};

const companies = [
  '타임폴리오자산운용',
  '------------',
  '삼성증권',
  '신한금융투자',
  '한국투자증권',
  '미래에셋대우',
  'NH투자증권',
  'KB증권',
  '키움증권',
  '------------',
  'IVY Club',
  '기타',
];

export default function Login() {
  const [userid, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [showRegForm, setShowRegForm] = useState(false);
  const [regId, setRegId] = useState('');
  const [regPwd, setRegPwd] = useState('');
  const [regCnfrmPwd, setRegCnfrmPwd] = useState('');
  const [regName, setRegName] = useState('');
  const [regEmail, setRegEmail] = useState('');
  const [regComp, setRegComp] = useState('');
  const [regDept, setRegDept] = useState('');

  const dispatch = useAuthDispatch();
  const { msgBox: m, logger } = useMessageState();

  const handleSubmit = async (e: FormEventTy) => {
    e.preventDefault();
    const params = { userid, password };
    callAxios({
      m,
      logger: null, // unauth 상태니 로깅 안보여주기,
      url: '/Auth/Login',
      params,
      onSuccess: (data) => {
        dispatch({ type: 'LOGIN', res: data });
        // accessToken 만료하기 1분 전에 로그인 연장
        setTimeout(
          () => onSilentRefresh(m, logger, dispatch),
          JWT_EXPIRY_TIME - 60 * 1000,
        );
      },
      onError: (errMsgs) => {
        m.alert(`[Login fail] ${errMsgs.join('\n')}`);
        dispatch({ type: 'LOGIN_ERROR', errMsg: errMsgs.join('\n') });
      },
    });
  };

  const findId = async () => {
    const email = await m.prompt('registered email');
    if (!email) return;
    callAxios({
      m,
      logger: null,
      url: '/Auth/FindId',
      params: { email },
      onSuccess: () => m.alert('An email with your ID was sent'),
      onError: (errMsgs) => m.alert(`[FindId fail] ${errMsgs.join('\n')}`),
    });
  };

  const reqPwdReset = async () => {
    const email = await m.prompt('registered email');
    if (!email) return;
    callAxios({
      m,
      logger: null,
      url: '/Auth/ReqPwdReset',
      params: { email },
      onSuccess: () => m.alert('An email with password reset link was sent'),
      onError: (errMsgs) => m.alert(`[Pwd Reset fail] ${errMsgs.join('\n')}`),
    });
  };

  const register = () => {

  }

  return (
    <div className="login-wrapper">
      <h2>Please Log In</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="userId">
          <p>User Id</p>
          <input
            type="text"
            name="userId"
            onChange={(e) => setUserId(e.target.value)}
          />
        </label>
        <br />
        <br />
        <label htmlFor="password">
          <p>Password</p>
          <input
            type="password"
            name="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <br />
        <br />
        <div>
          <button type="submit" className="btn btn-primary">
            Log in
          </button>
        </div>
      </form>
      <hr className="narrow light" />
      <div>
        <button type="button" className="btn btn-link" onClick={() => findId()}>
          Forgot your ID?
        </button>
        <br />
        <button
          type="button"
          className="btn btn-link"
          onClick={() => reqPwdReset()}
        >
          Forgot your password?
        </button>
        <br />
        <button
          type="button"
          className="btn btn-link"
          onClick={() => setShowRegForm(true)}
        >
          Register as a new user
        </button>
        <Dialog
          visible={showRegForm}
          style={{ width: '380px', height: '480px' }}
          onHide={() => setShowRegForm(false)}
        >
          <div className="container text-left children-mb-2">
            <div>
              Id{' '}
              <span style={{ color: 'gray' }}>
                (영문 대문자,소문자,숫자,점(.),언더바(_) 가능)
              </span>
              <br />
              <input
                type="text"
                value={regId}
                onChange={(e) => setRegId(e.target.value)}
              />
            </div>
            <div>
              Password{' '}
              <span style={{ color: 'gray' }}>
                (6자 이상 / 영문 대문자,소문자,숫자,특수문자 필요)
              </span>
              <br />
              <input
                type="password"
                value={regPwd}
                onChange={(e) => setRegPwd(e.target.value)}
              />
            </div>
            <div>
              Confirm Password
              <br />
              <input
                type="password"
                value={regCnfrmPwd}
                onChange={(e) => setRegCnfrmPwd(e.target.value)}
              />
            </div>
            <div>
              Name
              <br />
              <input
                type="text"
                value={regName}
                onChange={(e) => setRegName(e.target.value)}
              />
            </div>
            <div>
              Email
              <br />
              <input
                type="text"
                value={regEmail}
                onChange={(e) => setRegEmail(e.target.value)}
              />
            </div>
            <div>
              회사
              <br />
              <select
                value={regComp}
                onChange={(e) => setRegComp(e.target.value)}
              >
                {companies.map((c) => (
                  <option key={c}>{c}</option>
                ))}
              </select>
            </div>
            <div>
              부서
              <br />
              <input
                type="text"
                value={regDept}
                onChange={(e) => setRegDept(e.target.value)}
              />
            </div>
            <hr className="narrow light" />
            <Button label="Register" btnRole="Ok" onClick={() => register()} />
          </div>
        </Dialog>
      </div>
    </div>
  );
}
