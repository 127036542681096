import { z } from 'zod';
import {
  StrgCate,
  StrgSubCate,
  VhGrpTy,
  VhclTy,
  strgCateSchema,
  strgSubCateSchema,
  vhGrpTySchema,
  vhclTySchema,
} from './Tms';
import { zodPosiInt, zodPosiNumber, zodDate, zodDateNullable } from './ZodType';

export enum UserGroup { // 각 사용자는 한 그룹에만 속함 disjoint set
  None = 'None',
  PBS = 'PBS',
  SMIC = 'SMIC',
  Virtual = 'Virtual', // 인턴, 외부 가상매매
  IVY = 'IVY',
  Advisor = 'Advisor', // 외부 자문사
  Singa = 'Singa',
  Stk = 'Stk',
  ETF = 'ETF',
  AI = 'AI',
  AiOper = 'AiOper',
  PE = 'PE',
  AiIntern = 'AiIntern',
  Oper = 'Oper',
  Mkt = 'Mkt', // 마케팅
  Mgt = 'Mgt', // 경영관리
  MgtOper = 'MgtOper', // PI 주문
  Cmpl = 'Cmpl',
  Staff = 'Staff',
  Dev = 'Dev',
  Etc = 'Etc',
  Timefolio = 'Timefolio',
  Capital = 'Capital',
  CapOper = 'CapOper',
  RFM = 'RFM',
}
const userGroupSchema = z.nativeEnum(UserGroup);

export enum UserTeam {
  None = 'None',
  Stk1 = 'Stk1',
  Stk2 = 'Stk2',
}
const userTeamSchema = z.nativeEnum(UserTeam);

export enum Company {
  House = 'House',
  Singa = 'Singa',
  SMIC = 'SMIC',
  IVY = 'IVY',
  SS = 'SS',
  KI = 'KI',
  SH = 'SH',
  MA = 'MA',
  NH = 'NH',
  KB = 'KB',
  KW = 'KW',
  CSQ = 'CSQ',
  BLN = 'BLN',
  EXP = 'EXP',
  MICH = 'MICH',
  ETT = 'ETT',
  Capital = 'Capital',
}

export const getCompName = (comp: Company) => {
  switch (comp) {
    case Company.House:
      return '타임';
    case Company.Singa:
      return '싱가';
    case Company.SMIC:
      return '스믹';
    case Company.SS:
      return '삼성';
    case Company.KI:
      return '한투';
    case Company.SH:
      return '신한';
    case Company.MA:
      return '미래';
    case Company.NH:
      return 'NH';
    case Company.KB:
      return 'KB';
    case Company.KW:
      return '키움';
    case Company.CSQ:
      return '씨스퀘어';
    case Company.BLN:
      return '빌리언폴드';
    case Company.EXP:
      return '엑스포넨셜';
    case Company.MICH:
      return '미시간';
    case Company.ETT:
      return '으뜸';
    case Company.IVY:
      return 'IVY';
    default:
      return 'Unknown';
  }
};

export const PBSs = [
  Company.SS,
  Company.KI,
  Company.MA,
  Company.KB,
  Company.SH,
  Company.NH,
  Company.KW,
];

export const SwapBrokers = [
  Company.SS,
  Company.KI,
  Company.MA,
  Company.KB,
  Company.SH,
  Company.NH,
];

export interface Claims {
  Name: string;
  Comp: string;
  Group: string;
}

export interface TmsUser {
  Id: string;
  Name: string;
  Email: string;
  EmailConfirmed: boolean;
  Company: string | null;
  Department: string | null;
  Allowed: boolean;
  NormId: string;
  JoinDate: string;
  ExpDate: string | null;
  LockoutEnd: string | null;
  LockoutEnabled: boolean;
  AccessFailedCount: number;
  LastLogIn: string;
  SlackId: string | null;
  IP: string | null;
  showHelp: boolean;
  IsTmpPwd: boolean;
  invalid: boolean;
  Group: UserGroup | null;
  Team: UserTeam | null;
  Comp: string | null;
}

export const tmsUserSchema = z.object({
  Id: z.string(),
  Name: z.string(),
  Email: z.string(),
  EmailConfirmed: z.coerce.boolean(),
  Company: z.string().nullish(),
  Department: z.string().nullish(),
  Allowed: z.coerce.boolean(),
  NormId: z.string(),
  JoinDate: zodDate,
  ExpDate: zodDateNullable,
  LockoutEnd: z.string().nullish(),
  LockoutEnabled: z.coerce.boolean(),
  AccessFailedCount: zodPosiInt(false),
  LastLogIn: z.string(),
  SlackId: z.string().nullish(),
  IP: z.string().nullish(),
  showHelp: z.coerce.boolean(),
  IsTmpPwd: z.coerce.boolean(),
  invalid: z.coerce.boolean(),
  Group: userGroupSchema.nullish().readonly(),
  Team: userTeamSchema.nullable().readonly(),
  Comp: z.string().nullish().readonly(),
});

export interface TmsClaim {
  Id: string;
  IsUnique: boolean;
}
export const tmsClaimSchema = z.object({
  Id: z.string(),
  IsUnique: z.coerce.boolean(),
});

export enum MenuGroup {
  Fund = 'Fund',
  Master = 'Master',
  Front = 'Front',
  Middle = 'Middle',
  Back = 'Back',
  AI = 'AI',
  ETF = 'ETF',
  PI = 'PI',
  Tools = 'Tools',
  PBS = 'PBS',
  Singa = 'Singa',
  Chat = 'Chat',
  Util = 'Util',
  Admin = 'Admin',
}
const menuGroupSchema = z.nativeEnum(MenuGroup);

export interface WebMenu {
  Id: number;
  grp: MenuGroup;
  name: string;
  args: string | null;
  title: string | null;
  etitle: string | null;
  subGrp: string | null;
  ord: number;
  invalid: boolean;
  hide: boolean;
  react: boolean;
  obsolete: boolean;
  rawurl: string;
  url: string;
}

export const webMenuSchema = z.object({
  grp: menuGroupSchema,
  name: z.string(),
  args: z.string().nullish(),
  title: z.string().nullish(),
  etitle: z.string().nullish(),
  subGrp: z.string().nullish(),
  ord: zodPosiNumber(false),
  invalid: z.coerce.boolean(),
  hide: z.coerce.boolean(),
  react: z.coerce.boolean(),
  obsolete: z.coerce.boolean(),
  rawurl: z.string(),
  url: z.string(),
});

enum MenuPermission {
  None = 'None',
  Read = 'Read',
  Write = 'Write',
}
const menuPermissionSchema = z.nativeEnum(MenuPermission);

export interface WebMenuAuth {
  Id: number;
  userGrp: UserGroup;
  userId: string | null;
  menuGrp: MenuGroup;
  menu: string | null;
  args: string | null;
  permission: MenuPermission;
  userNm: string | null;
}
export const webMenuAuthSchema = z.object({
  userGrp: userGroupSchema,
  userId: z.string().nullish(),
  menuGrp: menuGroupSchema,
  menu: z.string().nullish(),
  args: z.string().nullish(),
  permission: menuPermissionSchema,
  userNm: z.string().nullish().readonly(),
});

export interface NginxAccessLog {
  Id: number;
  t: string;
  hostname: string;
  request_decoded: string | null;
  userId: string | null;
  userNm: string | null;
}

export interface UserClaim {
  Id: number;
  userId: string;
  ClaimId: string;
  ClaimValue: string;
  userNm: string | null;
}

export const userClaimSchema = z.object({
  userId: z.string(),
  ClaimId: z.string(),
  ClaimValue: z.string(),
  userNm: z.string().nullish().readonly(),
});

export default class User {
  readonly Id: string;

  readonly accessToken: string;

  readonly Name: string;

  readonly Comp: Company;

  readonly Group: UserGroup;

  readonly menus: WebMenu[];

  constructor(
    Id: string,
    accessToken: string,
    Name: string,
    Comp: Company,
    Group: UserGroup,
    menus: WebMenu[],
  ) {
    this.Id = Id;
    this.accessToken = accessToken;
    this.Name = Name;
    this.Comp = Comp;
    this.Group = Group;
    this.menus = menus;
  }

  get isHouse(): boolean {
    return this.Comp === Company.House;
  }

  get isPbs(): boolean {
    return this.Group === UserGroup.PBS;
  }

  get isAdvisor(): boolean {
    return this.Group === UserGroup.Advisor;
  }

  get isSmicOrIvy(): boolean {
    return [UserGroup.SMIC, UserGroup.IVY].contains(this.Group);
  }

  get isOmsOperator(): boolean {
    return [UserGroup.Dev, UserGroup.Oper, UserGroup.MgtOper].contains(
      this.Group,
    );
  }

  get isSupervisory(): boolean {
    return [UserGroup.Dev, UserGroup.Cmpl, UserGroup.Staff].contains(
      this.Group,
    );
  }

  get isAi(): boolean {
    return [UserGroup.AI, UserGroup.AiOper].contains(this.Group);
  }

  get isCapital(): boolean {
    return [UserGroup.Capital, UserGroup.CapOper].contains(this.Group);
  }

  get isDev(): boolean {
    return this.Group === UserGroup.Dev;
  }

  get isMgt(): boolean {
    return [UserGroup.Mgt, UserGroup.MgtOper].contains(this.Group);
  }

  get isAiOper(): boolean {
    return this.Group === UserGroup.AiOper;
  }

  get useEng(): boolean {
    return [Company.Singa].includes(this.Comp);
  }
}

export interface TmsUserStrg {
  Id: number;
  userGrp: UserGroup;
  userId: string | null;
  stId: string;
  invalid: boolean;
  dft: boolean;
  userNm: string | null;
  team: UserTeam | null;
}

export const tmsUserStrgSchema = z.object({
  userGrp: userGroupSchema,
  userId: z.string().nullish(),
  stId: z.string(),
  invalid: z.coerce.boolean(),
  dft: z.coerce.boolean(),
  userNm: z.string().nullish().readonly(),
});

export enum AuthPermission {
  None = 'None',
  NavOnly = 'NavOnly',
  MngrEval = 'MngrEval',
  WeeklyPlan = 'WeeklyPlan',
  Read = 'Read',
  Write = 'Write',
  ReadWrite = 'Read, Write',
}
const authPermissionSchema = z.nativeEnum(AuthPermission);

export enum AuthTag {
  WeekendOnly = 'WeekendOnly',
}
const authTagSchema = z.nativeEnum(AuthTag);

export interface VhStAuth {
  Id: string;
  d0: string;
  d1: string;
  ord: number | null;
  cate: string | null;
  note: string | null;
  userGrp: UserGroup | null;
  userTeam: UserTeam | null;
  userId: string | null;
  header: boolean;
  allVhs: boolean;
  vhTy: VhclTy | null;
  vhGrp: VhGrpTy | null;
  vhId: string | null;
  whole: boolean;
  strgs: boolean;
  mineOnly: boolean;
  teamSts: boolean;
  stCate: StrgCate | null;
  stSubCate: StrgSubCate | null;
  stId: string | null;
  wholeIfStrg: boolean;
  permission: AuthPermission;
  userNm: string | null;
  tags: AuthTag | null;
  invalid: boolean;
  disallow: boolean;
}

export const vhStAuthSchema = z.object({
  ord: zodPosiNumber(true),
  d0: zodDate,
  d1: zodDate,
  cate: z.string().nullish(),
  note: z.string().nullish(),
  userGrp: userGroupSchema.nullish(),
  userTeam: userTeamSchema.nullish(),
  userId: z.string().nullish(),
  header: z.boolean().nullish(),
  allVhs: z.boolean().nullish(),
  vhTy: vhclTySchema.nullish(),
  vhGrp: vhGrpTySchema.nullish(),
  vhId: z.string().nullish(),
  whole: z.boolean().nullish(),
  strgs: z.boolean().nullish(),
  mineOnly: z.boolean().nullish(),
  teamSts: z.boolean().nullish(),
  stCate: strgCateSchema.nullish(),
  stSubCate: strgSubCateSchema.nullish(),
  stId: z.string().nullish(),
  wholeIfStrg: z.boolean().nullish(),
  permission: authPermissionSchema,
  userNm: z.string().nullish(),
  tags: authTagSchema.nullish(), // TODO flags enum을 어떻게?
  invalid: z.coerce.boolean(),
  disallow: z.coerce.boolean(),
});
