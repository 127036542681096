import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Exporting from 'highcharts/modules/exporting';
import exportingcsv from 'highcharts/modules/export-data';
import './portfolio.scss';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import PerfChartsExDlg, { PerfChartsExDlgArgs } from './PerfChartsExDlg';
import { ValidData, callAxiosGet } from '../../../tmsutil';

Exporting(Highcharts);
exportingcsv(Highcharts);

interface Props {
  d: string;
  vh: string | null;
  st: string | null;
  isEtf: boolean;
  bmRt?: number | null;
  fundRt?: number | null;
}

type Periods = {
  Id: string;
  nm: string;
};

const dftChtOpt: Highcharts.Options = {
  chart: {
    type: 'line',
    width: 340,
    height: 240,
    zooming: {
      type: 'x',
    },
  },
  title: { text: '' },
  xAxis: { type: 'datetime' },
  yAxis: { title: { text: null } },
  credits: { enabled: false },
  legend: { itemStyle: { fontSize: '10px' }, margin: 5 },
  plotOptions: {
    line: {
      marker: { enabled: false },
      shadow: false,
      animation: false,
      lineWidth: 1,
    },
  },
  accessibility: { enabled: false },
  navigation: {
    buttonOptions: {
      verticalAlign: 'bottom',
    },
  },
};

// nav_intra_chart tmschart.js
const chtOptIntra: Highcharts.Options = {
  ...dftChtOpt,
  colors: ['#f00', '#cc0', '#00f', '#0c0', '#0cf', '#55c', '#c5c'],
};

// nav_daily_chart
const chtOptDaily: Highcharts.Options = {
  ...dftChtOpt,
  colors: ['#f00', '#00f', '#0f0', '#cc0', '#0c0', '#0cf', '#55c', '#c5c'],
};

// corr_daily_chart
const chtOptCorr: Highcharts.Options = {
  ...dftChtOpt,
  colors: ['#f00', '#00f', '#090', '#f77', '#77f'],
  yAxis: [{ title: { text: null }, min: 0, max: 1.1 }],
};

// std_daily_chart
const chtOptStd: Highcharts.Options = {
  ...dftChtOpt,
  colors: ['#000', '#f00', '#00f', '#090', '#f77', '#77f'],
};

// sec_wei_chart
const chtOptSecWei: Highcharts.Options = {
  ...dftChtOpt,
  chart: { ...dftChtOpt.chart, type: 'column' },
  xAxis: { labels: { useHTML: false, style: { fontSize: '10px' }, step: 1 } },
  yAxis: [{ title: { text: null }, labels: { style: { fontSize: '10px' } } }],
  plotOptions: {
    column: {
      pointPadding: 0, // 0.2,
      borderWidth: 0,
    },
    series: {
      shadow: false,
      animation: false,
      dataGrouping: { enabled: false },
    },
  },
};

export default function PerfCharts({ d, vh, st, isEtf, bmRt, fundRt }: Props) {
  const { msgBox: m, logger } = useMessageState();
  const [navPrd, setNavPrd] = useState<string | null>(null); // return 값
  const [stdPrd, setStdPrd] = useState<string | null>('y1'); //
  const [periods, setPeriods] = useState<Periods[]>([]); // 조회

  const [intra, setIntra] = useState<Highcharts.Options>(chtOptIntra);
  const [daily, setDaily] = useState<Highcharts.Options>(chtOptDaily);
  const [corrDaily, setCorrDaily] = useState<Highcharts.Options>(chtOptCorr);
  const [stdDaily, setStdDaily] = useState<Highcharts.Options>(chtOptStd);
  const [secWei, setSecWei] = useState<Highcharts.Options>(chtOptSecWei);

  // https://codesandbox.io/p/sandbox/highcharts-react-demo-fork-5pl16?file=%2Fdemo.jsx%3A8%2C3-8%2C42
  // https://github.com/highcharts/highcharts-react/issues/290
  // function updateNavIntraChart(bmRt, fundRt)
  const intraRef = useRef<{
    chart: Highcharts.Chart;
    container: React.RefObject<HTMLDivElement>;
  }>(null);

  const updateNavIntraChart = () => {
    const xNew = new Date().getTime() + 9 * 60 * 60 * 1000;
    for (let k = 0; k < 3; k += 1) {
      const srs = intraRef.current?.chart.series[k];
      if (srs && k !== 1) {
        // k==1 이면 마감기준가
        const rt = k === 0 ? fundRt : bmRt;
        const x = srs.data.length === 0 ? 0 : srs.data[srs.data.length - 1]?.x;
        if (x == null) return;
        if (xNew >= x + 1000 * 60 || srs.data.length === 0) {
          srs.addPoint([xNew, rt]);
        } else {
          srs.data[srs.data.length - 1].update([x, rt]);
        }
      }
    }
  };

  const callGet = (
    func: string,
    params: unknown,
    onSuccess: (data: ValidData) => void,
  ) =>
    callAxiosGet({
      m,
      logger,
      url: `/Fund/Portfolio/${func}`,
      params,
      onSuccess,
    });

  useEffect(() => {
    if (!bmRt || !fundRt) return;
    updateNavIntraChart();
  });

  const setNavIntraData = (data: Highcharts.SeriesLineOptions[]) => {
    const series = [...data];
    if (!series.length) return;
    series[0].lineWidth = 2;
    if (series.length > 1 && series[1].name === '마감') {
      series[1].visible = false;
    }
    for (let k = 3; k < series.length; k += 1) {
      series[k].visible = false;
    }
    setIntra((p) => ({
      ...p,
      series,
    }));
  };

  const setNavDailyData = (data: Highcharts.SeriesLineOptions[]) => {
    const series = [...data];
    if (!series.length) return;
    series[0].lineWidth = 2;
    for (let k = 2; k < series.length; k += 1) {
      series[k].visible = false;
    }
    setDaily((p) => ({
      ...p,
      series,
    }));
  };

  const setCorrDailyData = (data: Highcharts.SeriesLineOptions[]) => {
    const series = [...data];
    if (!series.length) return;
    series[0].lineWidth = 3;
    setCorrDaily((p) => ({
      ...p,
      series,
    }));
  };

  const setStdDailyData = (data: Highcharts.SeriesLineOptions[]) => {
    const series = [...data];
    if (!series.length) return;
    if (series.length > 1) series[1].visible = false;
    if (series.length > 2) series[2].visible = false;
    if (series.length > 4) series[4].visible = false;
    if (series.length > 5) series[5].visible = false;

    const net = _.find(series, (c) => c.name === 'net');
    if (!net) return;
    net.yAxis = 1;
    net.color = 'red';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const maxHt = _.maxBy(net.data, (v) => (v as any)[1]) as [string, number];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const minHt = _.minBy(net.data, (v) => (v as any)[1]) as [string, number];
    if (maxHt === undefined || minHt === undefined) return;
    let minH = 0;
    let maxH = maxHt[1];
    if (maxHt[1] < 105 && minHt[1] > -50) {
      minH = -50;
      maxH = 100;
    }
    // console.log('minH', minH, maxH, maxHt, minHt);
    setStdDaily((p) => ({
      ...p,
      yAxis: [
        { title: { text: '' }, min: 0 },
        { title: { text: 'net' }, opposite: true, min: minH, max: maxH },
      ],
      series,
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSecWeiData = (data: any) => {
    if (!data.secs.length) return;
    setSecWei(
      (p) =>
        ({
          ...p,
          xAxis: [{ categories: data.secs }],
          series: [
            { name: data.lnm, color: 'red', data: data.lw },
            { name: data.snm, color: 'blue', data: data.sw },
          ],
        }) as Highcharts.Options,
    );
  };

  useEffect(() => {
    if (!d || !vh || !st) return;
    callGet('/Charts', { d, vh, st, navPrd, stdPrd, isEtf }, (data) => {
      setNavIntraData(data.navIntra);
      setNavDailyData(data.navDaily);
      if (isEtf) setCorrDailyData(data.stdOrCorrDaily);
      else setStdDailyData(data.stdOrCorrDaily);
      setSecWeiData(data.secWei);
    });
  }, [d, vh, st, navPrd, stdPrd, isEtf]);

  useEffect(() => {
    if (!vh || !st) return;
    callGet('FiscalPeriods', { vh, st }, (data) => {
      setPeriods(data as Periods[]);
    });
  }, [vh, st, isEtf]);

  const periodsTemplate = (
    <select
      id="dcht_prd"
      value={navPrd || ''}
      style={{ float: 'right' }}
      onChange={(e) => setNavPrd(e.target.value)}
    >
      <option key={-1} value="" aria-label="nav_period" />
      {periods.map((v) => (
        <option key={v.Id} value={v.Id}>
          {v.nm}
        </option>
      ))}
    </select>
  );

  const stdPeriodsTemplate = (
    <select
      id="dcht_prd_std"
      value={stdPrd || ''}
      style={{ float: 'right' }}
      onChange={(e) => setStdPrd(e.target.value)}
    >
      {periods.map((v) => (
        <option key={v.Id} value={v.Id}>
          {v.nm}
        </option>
      ))}
    </select>
  );

  const [perfChartsExDlgArgs, setPerfChartsExDlg] =
    useState<PerfChartsExDlgArgs>({ visible: false, daily });

  const corrDailyChart = (
    <div className="col-3" style={{ width: 350, height: 250, zIndex: 3 }}>
      <div style={{ padding: '10px' }}>
        <span style={{ fontWeight: 'bold' }}>Correlation Chart</span>
      </div>
      <HighchartsReact highcharts={Highcharts} options={corrDaily} />
    </div>
  );

  const stdDailyChart = (
    <div className="col-3" style={{ width: 350, height: 250, zIndex: 3 }}>
      <div style={{ padding: '10px' }}>
        <span style={{ fontWeight: 'bold' }}>Std.Dev Chart</span>
        <span
          role="presentation"
          id="stdDailyChart_ex"
          style={{ cursor: 'pointer', color: 'gray' }}
          onClick={() => setPerfChartsExDlg({ visible: true, daily: stdDaily })}
        >
          (확대)
        </span>
        {stdPeriodsTemplate}
      </div>
      <HighchartsReact highcharts={Highcharts} options={stdDaily} />
    </div>
  );

  const isEtfCorrChart = isEtf ? corrDailyChart : stdDailyChart;

  return (
    <>
      <div className="row" style={{ width: '1400px' }}>
        <div className="col-3" style={{ width: 350, height: 250, zIndex: 3 }}>
          <div style={{ padding: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Intraday Chart</span>
          </div>
          <HighchartsReact
            highcharts={Highcharts}
            options={intra}
            ref={intraRef}
          />
        </div>
        <div className="col-3" style={{ width: 350, height: 250, zIndex: 3 }}>
          <div style={{ padding: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Daily Chart</span>
            <span
              role="presentation"
              id="navDailyChart_ex"
              style={{ cursor: 'pointer', color: 'gray' }}
              onClick={() => setPerfChartsExDlg({ visible: true, daily })}
            >
              (확대)
            </span>
            {periodsTemplate}
          </div>
          <HighchartsReact highcharts={Highcharts} options={daily} />
        </div>
        {isEtfCorrChart}
        <div className="col-3" style={{ width: 350, height: 250, zIndex: 3 }}>
          <div style={{ padding: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>Sector Weight</span>
          </div>
          <HighchartsReact highcharts={Highcharts} options={secWei} />
        </div>
      </div>
      <div className="col-3" style={{ width: 0, height: 0, zIndex: 3 }}>
        <PerfChartsExDlg
          args={perfChartsExDlgArgs}
          setArgs={setPerfChartsExDlg}
        />
      </div>
    </>
  );
}
