import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SimpleGridDataType } from 'tmslib/src/table/SimpleGrid';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import DateSelector from '../../shared/DateSelector';
import VhclSelector from '../../shared/VhclSelector';
import StrgSelector, { SimpleStrg } from '../../shared/StrgSelector';
import ProdSelector from '../../shared/ProdSelector';
import {
  UrlGrid,
  UrlGridArgs,
  emptyGridArgs,
  callAxios,
  utilPost,
  downloadFile,
  isSingleFund,
} from '../../tmsutil';
import { DftBtnStyleMx } from '../../AppTypes';
import { prodCashDivInfoSchema } from '../../Tms/Tms';
import { ProdCashDivInfo } from '../../Tms/Prod';
import { DailyCfg, dailyCfgSchema, dailyCfgTaxNavSchema } from '../../Tms/BO';
import {
  AmtWei,
  UstkPosDetail,
  Asset,
  UstkbbTkr,
  BSStatBatch,
  ustkbbTkrSchema,
  AstAllocByProd,
  PrftByVhId,
  PrftByProd,
  ClassBS,
  IdxNav,
  StkNav,
  FeeSum,
  UstkPos,
  SwapPositionByVhId,
  QueryBrkFee,
  queryBrkFeeSchema,
  InterestPos,
  AmtVhCcyByAstAlloc,
} from '../../Tms/Query';
import {
  MkFundMst,
  mkFundMstSchema,
  MkIvm,
  mkIvmSchema,
  MkFundDaily,
  mkFundDailySchema,
} from '../../Tms/MkFunds';
import FileUploadDialog from '../../shared/FileUploadDialog';
import ButtonWithHelp from '../../shared/ButtonWithHelp';

const currMenu = '/Back/Query';
const dftHeight = 500;

type PageFunc = 'GenFundDaily' | 'UploadKsdFundBS' | 'UploadKsdFundStl';

// 보유
// prettier-ignore
const amtWei: UrlGridArgs<AmtWei> = {
  url: `${currMenu}/AmtWei`,
  title: '종목별보유비중',
  columns: ['d', 'vhId', 'stId', 'prodId', 'prodNm', 'amtVhCcy', 'wei', 'nav', 'sec',],
  headers: ['날짜', '펀드명', '전략', '종목코드', '종목명', '보유금액', '비중', '기준가', '섹터',],
  editable: false,
  meta: { dftColWidth: 70 },
  widths: { d: 80, prodNm: 200, amtVhCcy: 100 },
  height: dftHeight,
  dftFormatter: (v, c) => ((c === 'wei' || c === 'nav') ? v?.toFixedWithComma(4) : v?.toFixedWithComma(0))
};

// prettier-ignore
const ustkPos: UrlGridArgs<UstkPos> = {
  url: `${currMenu}/UstkPos`,
  title: '국가별해외주식보유',
  columns: [],
  dynamicColumns: (res) => {
    const { brks }: { brks: string[] } = res.data.extras;
    const columns = ['cntry'].concat(
      ...brks.map((b) =>
        ['_USwap_long', '_USwap_short', '_USwap_sum'].map((f) => b + f),
      ),
    );
    const headers: string[] = ['cntry'].concat(
      ...brks.map(() => ['L', 'S', 'L+S']),
    );
    let headerGroups: [string, number][] = brks.map((b) => [b, 3]);
    headerGroups = [['', 1], ...headerGroups];
    return { columns, headers, headerGroups };
  },
  dftFormatter: (v, c) => {
    if (c === 'cntry') return v;
    if (c === 'wei') {
      const val = Number.isFinite(v) ? Number(v) * 100 : 0;
      return val?.toFixedWithComma(2);
    }
    return v?.toFixedWithComma(0);
  },
  widths: { cntry: 50 },
  meta: {
    dftColWidth: 110,
    useGlobalFilter: false,
  },
  height: 250,
  auxColumns: [['cntry', 'L', 'S', 'LS', 'wei']],
  auxHeaders: [['국가', 'L', 'S', 'L+S', '비중']],
  auxGridArgs: [
    {
      title: '합산',
      headerGroups: [
        ['', 1],
        ['전펀드합산', 3],
        ['국가별 보유비중', 1],
      ],
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    },
  ],
};

// prettier-ignore
const ustkPosDetail: UrlGridArgs<UstkPosDetail> = {
  url: `${currMenu}/UstkPosDetail`,
  title: '펀드/국가별 해외주식보유',
  columns: ['d', 'vhId', 'book', 'cntry', 'ls', 'amtVhCcy'],
  headers: ['날짜', '펀드', '북', '국가', 'LS', '보유금액'],
  editable: false,
  meta: { dftColWidth: 80 },
  widths: { ls: 70, amtVhCcy: 130 },
  height: dftHeight,
};

// prettier-ignore
const asset: UrlGridArgs<Asset> = {
  url: `${currMenu}/Asset`,
  title: '자산내역',
  columns: ['d', 'vhId', 'stId', 'prodId', 'nm', 'pos', 'close', 'amtVhCcy', 'isF', 'astTy', 'invTy', 'exch', 'evaluate', 'under', 'undernm', 'underAstTy', 'underInvTy', 'underExch', 'underofunderId', 'underofundernm', 'underofunderExch', 'sec',],
  headers: ['날짜', '펀드', '전략', '종목코드', '종목명', '보유수량', '종가', '원화보유금액', '직간접', '자산구분', '투자형태', '상장시장', '시가평가여부', '기초자산코드', '기초자산명', '기초자산구분', '기초자산투자형태', '기초자산 상장시장', '기초자산 기업코드', '기초자산 기업명', '기초자산기업 상장시장', '섹터',],
  editable: false,
  meta: { dftColWidth: 70 },
  widths: { nm: 200, undernm: 200, underInvTy: 120, underofunderId: 130, underAstTy: 130, underofundernm: 130, underofunderExch: 130, underExch: 130, close: 100, pos: 100, amtVhCcy: 130, },
  height: dftHeight,
};

// prettier-ignore
const ustkbbTkr: UrlGridArgs<UstkbbTkr> = {
  url: `${currMenu}/UstkbbTkr`,
  title: '해외주식보유티커',
  columns: ['d', 'Id', 'nm', 'bbTkr'],
  headers: ['날짜', '종목코드', '종목명', '블룸티커'],
  editable: false,
  meta: { dftColWidth: 200 },
  widths: { d: 100 },
  height: dftHeight,
  schema: ustkbbTkrSchema,
};

// prettier-ignore
const ustkProdCashDivInfo: UrlGridArgs<ProdCashDivInfo> = {
  url: `${currMenu}/ProdCashDivInfo`,
  title: '해외주식배당정보',
  columns: ['prodId', 'nm', 'bbTkr', 'annd', 'exd', 'recd', 'payd', 'amt', 'freq', 'ty',],
  headers: ['종목코드', '종목명', '블룸티커', '공시일', '배당락일', '기준일', '결제일', '배당금', '배당종류', '비고',],
  editable: true,
  widths: { prodId: 120, nm: 150, bbTkr: 120 },
  height: dftHeight,
  meta: { dftColWidth: 100 },
  schema: prodCashDivInfoSchema,
};

// 카테고리별보유금액
// prettier-ignore
const amtVhCcyByAstAlloc: UrlGridArgs<AmtVhCcyByAstAlloc> = {
  url: `${currMenu}/BSStatBatch`, // BSStatBatch AmtVhCcyByAstAlloc
  title: '카테고리별보유금액',
  columns: ['d', 'vhId', 'na', 'dsl', 'ddl', 'fsl', 'fdl', 'dss', 'dds', 'fss', 'fds', 'mzn', 'etc'],
  headers: ['날짜', '운용상품', '순자산', '국내주식롱', '국내파생롱', '해외주식롱', '해외파생롱', '국내주식숏', '국내파생숏', '해외주식숏', '해외파생숏', '메자닌', '기타'],
  editable: false,
  widths: { d: 100, vhId: 100 },
  height: dftHeight,
  meta: { dftColWidth: 100 },
};

// prettier-ignore
const astAllocByProd: UrlGridArgs<AstAllocByProd> = {
  url: `${currMenu}/AstAllocByProd`,
  title: '종목별카테고리',
  columns: ['d', 'vhId', 'prodId', 'prodNm', 'cate', 'amtVhCcy'],
  headers: ['날짜', '운용상품', '종목코드', '종목명', '카테고리', '보유금액'],
  editable: false,
  meta: { dftColWidth: 150 },
  widths: { d: 100, vhId: 100 },
  height: dftHeight,
};

/*
const posMenu = [
  ustkPosDetail,
  asset,
  ustkbbTkr,
  ustkProdCashDivInfo,
  amtVhCcyByAstAlloc,
  astAllocByProd,
];
*/

// 보유 end

// 거래
// prettier-ignore
const prftByVhId: UrlGridArgs<PrftByVhId> = {
  url: `${currMenu}/PrftByVhId`,
  title: '일별 계좌 롱숏손익',
  columns: ['d', 'vhId', 'stId', 'na', 'lprd', 'lprf', 'lad', 'laf', 'sprd', 'sprf', 'sad', 'saf',],
  headers: ['날짜', '상품', '전략', '순자산', '롱손익(국내)', '롱손익(해외)', '롱보유(국내)', '롱보유(해외)', '숏손익(국내)', '숏손익(해외)', '숏보유(국내)', '숏보유(해외)',],
  editable: false,
  widths: { d: 80, vhId: 80, na: 120 },
  height: dftHeight,
  meta: { dftColWidth: 100 },
};

// prettier-ignore
const prftByProd: UrlGridArgs<PrftByProd> = {
  url: `${currMenu}/PrftByProd`,
  title: '종목별 롱숏손익',
  columns: ['LS', 'Code', 'name', 'under', 'undernm', 'prft', 'sec'],
  headers: ['LS', '종목코드', '종목명', '기초자산코드', '기초자산명', 'prft', '섹터',],
  editable: false,
  widths: { LS: 30, prft: 100, sec: 50 },
  height: dftHeight,
  meta: { dftColWidth: 150 },
};

const trdMenu = [prftByVhId, prftByProd];
// 거래 end

// 기준가

// prettier-ignore
const classBS: UrlGridArgs<ClassBS> = {
  url: `${currMenu}/ClassBS`,
  title: '클래스기준가',
  columns: ['d', 'vhId', 'stId', 'prin', 'units', 'prft', 'cost', 'na', 'nav'],
  headers: ['날짜', '운용상품', '매니저', '원본액', '좌수', '손익', '비용', '순자산', '기준가',],
  editable: false,
  meta: { dftColWidth: 110 },
  widths: { d: 80, nav: 80 },
  height: dftHeight,
  dftFormatter: (v, c) => {
    if (c === 'nav') {
      return v?.toFixedWithComma(4);
    }
    return v?.toFixedWithComma(0);
  },
};

// prettier-ignore
const bSStatBatch: UrlGridArgs<BSStatBatch> = {
  url: `${currMenu}/BSStatBatch`,
  title: '펀드별통계',
  columns: ['d', 'vhId', 'stId', 'na', 'dsl', 'ddl', 'fsl', 'fdl', 'dss', 'dds', 'fss', 'fds', 'mzn', 'etc',
    'nav', 'prft', 'rt', 'm3rt', 'm3std', 'm6rt', 'm6std', 'mtdrt', 'mtdstd', 'ytdrt', 'ytdstd', 'y1rt', 'y1std', 'd1stdF', 'd1stdL', 'd1stdS'],
  headers: ['날짜', '운용상품', '전략', '순자산', '국내주식롱', '국내파생롱', '해외주식롱', '해외파생롱', '국내주식숏', '국내파생숏', '해외주식숏', '해외파생숏', '메자닌', '기타',
    '기준가', '손익', '당일수익률', '3M수익률', '3M변동성', '6M수익률', '6M변동성', 'mtd수익률', 'mtd변동성', 'ytd수익률', 'ytd변동성', 'Y1수익률', 'Y1변동성', 'D1변동성', 'D1롱변동성', 'D1숏변동성',
  ],
  editable: false,
  meta: {
    dftColWidth: 80,
  },
  widths: { na: 100, dsl: 100, ddl: 100, fsl: 100, fdl: 100, dss: 100, dds: 100, fss: 100, fds: 100, mzn: 100, etc: 100, },
  height: dftHeight,
  dftFormatter: (v: SimpleGridDataType, c: keyof BSStatBatch) => {
    if (c.includes('rt') || c.includes('std')) return v?.toFixedWithComma(4);
    if (['d', 'vhId', 'stId'].indexOf(c) < 0) return v?.toFixedWithComma(0);
    return v;
  },
};

// prettier-ignore
const idxNav: UrlGridArgs<IdxNav> = {
  url: `${currMenu}/IdxNav`,
  title: '통합기준가 (HFund)',
  // prettier-ignore
  columns: ["d", "tolprin", "tolna", "csprin", "csna", "tolNav", "csNav", "NavByTolNadivByCsrt"],
  // prettier-ignore
  headers: ["일자", "통합원본", "통합순자산", "시딩원본", "시딩순자산", "전체수익률", "시딩수익률", "통합기준가"],
  meta: { dftColWidth: 130, height: 300 },
  height: 300,
  dftFormatter: (v, c) => {
    if (c === 'tolNav' || c === 'csNav' || c === 'NavByTolNadivByCsrt') {
      return v?.toFixedWithComma(2);
    }
    return v?.toFixedWithComma(0);
  },
  auxGridArgs: [
    { title: '통합기준가 (VFund)' },
    { title: '통합기준가 (AIFund)' },
  ],
};

// stTy: stk AI
// prettier-ignore
const stkNav: UrlGridArgs<StkNav> = {
  url: `${currMenu}/StkNav`,
  title: '주식팀통합기준가',
  columns: ['d', 'tdnaSum', 'ydnaSum', 'rt', 'cumrt', 'stIds', 'vhIds'],
  headers: ['날짜', '당일순자산합', '전일순자산합', '당일수익률', '통합기준가', '포함전략', '포함운용상품'],
  widths: { tdnaSum: 120, ydnaSum: 120, stIds: 150, vhIds: 300 },
  meta: { dftColWidth: 100, height: 300 },
  dftFormatter: (v, c) => {
    if (c === 'cumrt') return v?.toFixedWithComma(2);
    if (c === 'rt') return v?.toFixedWithComma(4);
    return v?.toFixedWithComma(0);
  },
  height: 300,
};

// 기준가 end

// 수수료/약정
// prettier-ignore
const auxColumnDft = ['tbty', 'brkcls', 'book', 'brkId', 'nm2', 'amt', 'fee', 'amtweiByCash', 'feeweiByCash']
// prettier-ignore
const auxHeaderDft = ['테이블', '구분', '북', '증권사', '브로커', '거래대금', '수수료', '거래대금/국내주식Cash', '수수료/국내주식Cash']
// prettier-ignore
const feeSum: UrlGridArgs<FeeSum> = {
  url: `${currMenu}/GetFeeSum`,
  title: '펀드수수료',
  columns: ['brkcls', 'amt'],
  headers: ['자산', '거래대금'],
  dftFormatter: (v, c) => {
    if (c === 'amt' || c === 'fee' || c === 'targetfee' || c === 'yfeert') {
      return v?.toFixedWithComma(0);
    }
    return v?.toFixedWithComma(2);
  },
  dftStyler: (v, c, r) => {
    if (r.original.brkcls === '총 합산') return { backgroundColor: '#fee' };
    if (r.original.brkcls?.includes('합산')) return { backgroundColor: '#ffa' };
    return null;
  },
  widths: { brkId: 50, nm2: 150, amt: 120, fee: 100 },
  meta: {
    dftColWidth: 90,
    useGlobalFilter: false,
  },
  height: undefined,
  // prettier-ignore
  auxColumns: [
    ['nm2', 'dstrSd', 'feert', 'amt', 'yfeert', 'mfeert', 'targetfee', 'fee', 'fulfillrt',],
    [...auxColumnDft, 'amtweiBytgtSum', 'feeweiBytgtSum',],
    [...auxColumnDft, 'amtweiBytgtSum', 'feeweiBytgtSum',],
    [...auxColumnDft, 'amtweiByWhSum', 'feeweiByWhSum', 'amtweiByBrk', 'feeweiByBrk',],
  ],
  // prettier-ignore
  auxHeaders: [
    ['구분', '약정기준일', '수수료율(bp)', '투자금액', '연간(bp)', '월간(bp)', '월약정', '실현약정(당월)', '달성률',],
    [...auxHeaderDft, '거래대금/테이블합', '수수료/테이블합',],
    [...auxHeaderDft, '거래대금/테이블합', '수수료/테이블합',],
    [...auxHeaderDft, '거래대금/전체', '수수료/전체', '거래대금/pbs', '수수료/pbs',],
  ],
  auxGridArgs: [
    { title: '판매사별' },
    { title: '테이블(All)' },
    { title: '테이블(Users)' },
    { title: '테이블(PBS)' },
  ],
};

// saveUrl : /Back/Query/SaveGetQueryBrkFee
// prettier-ignore
const queryBrkFee: UrlGridArgs<QueryBrkFee> = {
  url: `${currMenu}/QueryBrkFee`,
  title: '펀드수수료정보',
  columns: ['tablety', 'brkcls', 'ord', 'acctId', 'nm', 'nm2'],
  headers: ['테이블*', '구분명*', '순서*', '계좌*', '계좌명', '계좌명2'],
  editable: true,
  meta: { dftColWidth: 100 },
  widths: { nm: 150, nm2: 150 },
  height: dftHeight,
  infoMsg: `테이블=> All: 수수료합산전체, Users: 사용자지정, PBS: PBS`,
  schema: queryBrkFeeSchema,
};

// prettier-ignore
const interestPos: UrlGridArgs<InterestPos> = {
  url: `${currMenu}/InterestPos`,
  title: '관심종목',
  columns: ['d', 'vhId', 'stId', 'prodId', 'nm', 'pos', 'close', 'posAmt', 'wei',],
  headers: ['날짜', '펀드', '매니저', '종목코드', '종목명', '수량', '종가', '보유금액', '비중',],
  widths: { nm: 200, posAmt: 120 },
  height: dftHeight,
  meta: {
    dftColWidth: 80,
    height: dftHeight, // 두번째 테이블에도 적용될수 있게
    useGlobalFilter: false,
  },
};

const feeMenu = [feeSum, queryBrkFee];
// 수수료/약정 end

// 타사
// prettier-ignore
const boFundMst: UrlGridArgs<MkFundMst> = {
  url: `${currMenu}/MkFundMst`,
  title: '헤지펀드',
  columns: ['Id', 'nm', 'nm2', 'fundTy', 'initd', 'brkId', 'ivmId', 'useTy', 'ord', 'newfundId', 'chd',],
  headers: ['Id', '펀드이름(예탁원)', '편드단축이름', '타입', '시작일', '증권사Id', '운용사Id', '사용타입', '순서', '변경코드', '코드변경일',],
  editable: true,
  meta: { dftColWidth: 90 },
  widths: { Id: 110, nm: 500, nm2: 300, initd: 80, endd: 80, chd: 80 },
  height: dftHeight,
  schema: mkFundMstSchema,
  infoMsg: `타입: None, Hedge, Venture, ETF`,
};

// prettier-ignore
const boIvm: UrlGridArgs<MkIvm> = {
  url: `${currMenu}/MkIvm`,
  title: '운용사',
  columns: ['Id', 'nm', 'nm2', 'ord'],
  headers: ['Id', '이름', '이름2', '순서'],
  editable: true,
  meta: { dftColWidth: 110 },
  widths: { nm2: 150 },
  height: dftHeight,
  schema: mkIvmSchema,
};

// prettier-ignore
const funddailyErr: UrlGridArgs<MkFundDaily> = {
  url: `${currMenu}/MkFundDailyErr`,
  title: '타사기준가(에러)',
  columns: ['d', 'fundId', 'fundnm', 'ivmnm', 'nav', 'b'],
  headers: ['날짜', '펀드코드', '펀드명', '운용사명', '기준가', '전일기준가'],
  editable: true,
  meta: { dftColWidth: 100 },
  widths: { d: 90, fundId: 120, fundnm: 300, ivmnm: 200 },
  height: dftHeight,
  schema: mkFundDailySchema,
};

// prettier-ignore
const funddaily: UrlGridArgs<MkFundDaily> = {
  url: `${currMenu}/MkFundDaily`,
  title: '타사기준가(펀드별)',
  columns: ['d', 'fundId', 'fundnm', 'ivmnm2', 'nav', 'b', 'rt', 'taxnav', 'na', 'prin',],
  headers: ['날짜', '펀드코드', '펀드명', '운용사명', '기준가', '전일기준가', '수정기준가', '과표기준가', '순자산', '원본',],
  editable: false,
  meta: { dftColWidth: 100 },
  widths: { d: 90, fundId: 120, fundnm: 300, ivmnm: 150 },
  height: dftHeight,
};

const mkFundMenu = [boFundMst, boIvm, funddailyErr, funddaily];
// 타사 end

// 잔고
// prettier-ignore
const dailyCfg: UrlGridArgs<DailyCfg> = {
  url: `${currMenu}/DailyCfg`,
  title: '데일리설정',
  columns: ['ty', 'prd', 'line', 'ord', 'vhIn', 'stIn', 'title', 'memo'],
  headers: ['차트타입', '기간', '행', '열', 'vhIn', 'stIn', 'title', 'memo'],
  editable: true,
  meta: { dftColWidth: 80 },
  widths: { prd: 100, ord: 50, vhIn: 200, stIn: 500, title: 200, memo: 90 },
  height: dftHeight,
  schema: dailyCfgSchema,
};

// prettier-ignore
const dailyCfgTaxNav: UrlGridArgs<DailyCfg> = {
  url: `${currMenu}/DailyCfgTaxNav`,
  title: '과표기준가클래스설정',
  columns: ['vhId', 'taxnavcls', 'memo'],
  headers: ['운용상품Id', '과표기준가클래스', 'memo'],
  editable: true,
  meta: { dftColWidth: 120 },
  widths: { vhId: 100 },
  height: dftHeight,
  schema: dailyCfgTaxNavSchema,
};

const assetDailyMenu = [dailyCfg, dailyCfgTaxNav];
// 타사 end

const dftFormatterForSwapPositionByVhId = (
  v: SimpleGridDataType,
  c: keyof SwapPositionByVhId,
) => {
  if (
    c === 'ldiffdrt' ||
    c === 'sdiffdrt' ||
    c === 'ldifffrt' ||
    c === 'sdifffrt'
  ) {
    return v?.toFixedWithComma(2);
  }
  return v?.toFixedWithComma(0);
};

// prettier-ignore
const swapPositionByVhIdDft: UrlGridArgs<SwapPositionByVhId> = {
  url: `${currMenu}/SwapPositionByVhId`,
  title: '스왑포지션및한도관리',
  // prettier-ignore
  columns: ['book', 'vhId', 'ltotd', 'ldiffd', 'ldiffdrt', 'lprd', 'stotd', 'sdiffd', 'sdiffdrt', 'sprd', 'ltotf', 'ldifff', 'ldifffrt', 'lprf', 'stotf', 'sdifff', 'sdifffrt', 'sprf',],
  // prettier-ignore
  headers: ['PBS', '펀드', '총한도액', '잔여한도액', '잔여한도(%)', '보유금액', '총한도액', '잔여한도액', '잔여한도(%)', '보유금액', '총한도액', '잔여한도액', '잔여한도(%)', '보유금액', '총한도액', '잔여한도액', '잔여한도(%)', '보유금액',],
  headerGroups: [
    ['구분', 2],
    ['국내(L)', 4],
    ['국내(S)', 4],
    ['해외(L)', 4],
    ['해외(S)', 4],
  ],
  dftFormatter: dftFormatterForSwapPositionByVhId,
  widths: { book: 50 },
  height: 400,
  meta: {
    dftColWidth: 90,
    useGlobalFilter: false,
  },
  infoMsg: `스왑포지션 및 한도관리(단위: 억)`,
  // prettier-ignore
  auxColumns: [['vhId', 'lprd', 'lprdInput', 'sprd', 'sprdInput', 'lprf', 'lprfInput', 'sprf', 'sprfInput',],],
  // prettier-ignore
  auxHeaders: [['펀드', '국내롱한도액', '국내롱입력', '국내숏한도액', '국내숏입력', '해외롱한도액', '해외롱입력', '해외숏한도액', '해외숏입력',],],
};

const btnClass = DftBtnStyleMx;
const linkClass = 'btn btn-link';
type T = { Id: number }; // 임의 IId 타입

export default function Qeury() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();

  const d = searchParams.get('d') || info?.currBizDay || '';
  const [d0, setD0] = useState(d);
  const vh = searchParams.get('vh') || 'ALL_FUNDS';
  const [st, setSt] = useState<SimpleStrg | null>(null);

  const [inStrg, setInStrg] = useState<string | null>(null);
  const [prodId, setProdId] = useState<string | null>(null);

  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [gridArgs, setGridArgs] = useState<UrlGridArgs<T>>(emptyGridArgs);

  const [fundIds, setFundIds] = useState<string>('');

  const [fileUploadVisible, setFileUploadVisible] = useState<boolean>(false);
  const [fileUploadTitle, setFileUploadTitle] = useState<string>('');
  const [fileUploadUrl, setFileUploadUrl] = useState<string>('');
  const [warnResMsg, setWarnResMsg] = useState<string | null>(null);

  const singleVhFuncs = ['InterestPos', 'AssetAlloc'];

  const getParams = (url: string) => {
    if (!url) return {};
    const func = url.split('/').pop();
    if (!func) return {};
    if (func.isIn(...singleVhFuncs)) {
      return { d, vh, st: st?.Id };
    }
    const dftParams = { d, d0, vhOrGrp: vh, st: st?.Id ?? null };
    if (func.isIn('AmtWei')) {
      return { ...dftParams, prodId };
    }
    if (func.isIn('BSStatBatch', 'PrftByProd')) {
      return { ...dftParams, st: inStrg || st?.Id };
    }
    if (func.isIn('MkFundDaily', 'GenFundDaily')) {
      return { ...dftParams, fundIds };
    }
    return dftParams;
  };

  const clearArgs = () => {
    setWarnResMsg(null);
    setGridArgs(emptyGridArgs);
  };

  useEffect(() => {
    clearArgs();
  }, [d]);

  const query = (args: unknown) => {
    clearArgs();
    setRefreshNeeded((p) => p + 1);
    const iargs = args as UrlGridArgs<T>;
    const func = iargs.url.split('/').pop();
    if (func?.isIn(...singleVhFuncs)) {
      if (!isSingleFund(vh)) {
        m.alert(`${iargs.title}  ${vh} 로 조회불가, 단일 펀드만 조회가능`);
        return;
      }
    }
    setGridArgs(iargs);
  };

  const getButton = (a: unknown) => {
    let { title } = a as UrlGridArgs<T>; // args title
    if (title.startsWith('통합기준가 ')) {
      title = '통합기준가';
    }
    return (
      <ButtonWithHelp
        key={title}
        helpid={title}
        className={btnClass}
        onClick={() => query(a)}
        label={title}
      />
    );
  };

  const call = (func: PageFunc, params: unknown, confirmMsg: string) => {
    callAxios({
      m,
      logger,
      url: `${currMenu}/${func}`,
      params,
      confirmMsg,
      onSuccess: (data, res) => {
        if (!res.data.warnings?.length) {
          setWarnResMsg(`${confirmMsg ?? ''} OK`);
        } else {
          setWarnResMsg([confirmMsg].concat(res.data.warnings).join('\n'));
        }
      },
      onBegin: () => clearArgs(),
    });
  };

  const swapPositionByVhId: UrlGridArgs<SwapPositionByVhId> = {
    ...swapPositionByVhIdDft,
    auxGridArgs: [
      {
        title: '입력 값 (단위: 원)',
        headerGroups: [
          ['펀드', 1],
          ['국내', 4],
          ['해외', 4],
        ],
        meta: {
          editable: true,
          dftColWidth: 120,
          updateField: (original, rowIdx, colId, value) => {
            if (Number.isNaN(value) || Number(value) <= 0) {
              m.alert('숫자 및 0 보다 큰값을 입력');
              return;
            }
            if (
              colId.isIn('lprdInput', 'sprdInput', 'lprfInput', 'sprfInput')
            ) {
              let prodTy = null;
              if (colId === 'lprdInput') prodTy = 'WLMTDL';
              if (colId === 'sprdInput') prodTy = 'WLMTDS';
              if (colId === 'lprfInput') prodTy = 'WLMTFL';
              if (colId === 'sprfInput') prodTy = 'WLMTFS';
              const par = {
                d,
                ty: 'SwapLimitAmt',
                vh: original.vhId,
                prod: prodTy,
                memo: value,
              };
              utilPost(m, logger, 'SaveMemo', par, () =>
                query(swapPositionByVhId),
              );
            }
          },
        },
      },
    ],
  };

  // 파일없는 경우 TODO
  const getFile = (funcNm: string, fnm: string) => {
    downloadFile({
      m,
      logger,
      url: `${currMenu}/Download${funcNm}`,
      params: { d, fnm },
    });
  };

  // 관심종목파일
  const getInterestPosBatch = () => {
    clearArgs();
    getFile('InterestPosFile', 'TFOLIOA3');
    getFile('InterestPosFile', 'TFOLIOA4');
  };

  const ksdFileUpload = (url: PageFunc) => {
    clearArgs();
    let title = '';
    if (url === 'UploadKsdFundBS') {
      title = '기준가';
    } else if (url === 'UploadKsdFundStl') {
      title = '분배금';
    } else {
      m.alert('파일업로드 메뉴 이상');
      return;
    }
    setFileUploadVisible(true);
    setFileUploadTitle(title);
    setFileUploadUrl(`${currMenu}/${url}`);
  };

  return (
    <div style={{ minWidth: '1500px' }} className="children-me-2">
      <DateSelector
        value={d}
        initDate0={d0}
        onChange={(date) => date !== d && setSearchParams({ d: date, vh })}
        onChangeD0={(date0) => setD0(date0)}
      />
      <VhclSelector
        d={d}
        meta
        all
        tfim
        onChange={(vhcl) =>
          vhcl && vhcl.Id !== vh && setSearchParams({ d, vh: vhcl.Id })
        }
        value={vh}
      />
      <StrgSelector
        d={d}
        onChange={
          // (v) =>
          // v && st !== v.Id && setSearchParams({ d, vh, st: v.Id })
          setSt
          // setSearchParams({ d, vh, st: v == null ? [''] : v.Id })
        }
        empty
        whole
      // team
      // exc={[VhGrpTy.Troika, VhGrpTy.TFIM]}
      // useTgt
      />
      <input
        type="text"
        id="inStrg"
        style={{ width: '100px' }}
        placeholder="전략 필터"
        onChange={(e) => setInStrg(e.target.value ?? null)}
      />
      <ProdSelector onChange={(e) => setProdId(e?.Id ?? null)} />
      <hr className="narrow light" />
      <b>보유</b>&nbsp;&nbsp;
      {[
        amtWei,
        ustkPos,
        ustkPosDetail,
        asset,
        ustkbbTkr,
        ustkProdCashDivInfo,
        amtVhCcyByAstAlloc,
        astAllocByProd,
      ].map((v) => getButton(v))}
      <ButtonWithHelp
        className={btnClass}
        onClick={() => query(swapPositionByVhId)}
        helpid="스왑포지션및한도관리"
        label="스왑포지션및한도관리"
      />
      <hr className="narrow light" />
      <b>거래</b>&nbsp;&nbsp;
      {trdMenu.map((v) => getButton(v))}
      <hr className="narrow light" />
      <b>기준가</b>&nbsp;&nbsp;
      {[classBS, bSStatBatch, idxNav, stkNav].map((v) => getButton(v))}
      <hr className="narrow light" />
      <b>수수료/약정</b>&nbsp;&nbsp;
      {feeMenu.map((v) => getButton(v))}
      {getButton(interestPos)}
      <ButtonWithHelp
        className={btnClass}
        onClick={() => getInterestPosBatch()}
        helpid="관심종목파일"
        label="관심종목파일"
      />
      <hr className="narrow light" />
      <b>타사</b>&nbsp;&nbsp;
      {mkFundMenu.map((v) => getButton(v))}| 기준가 &nbsp;&nbsp;
      <ButtonWithHelp
        onClick={() => ksdFileUpload('UploadKsdFundBS')}
        helpid="기준가"
        label="파일선택"
      />
      &nbsp;&nbsp;분배금 &nbsp;&nbsp;
      <ButtonWithHelp
        onClick={() => ksdFileUpload('UploadKsdFundStl')}
        helpid="분배금"
        label="파일선택"
      />
      펀드코드:{' '}
      <input
        type="text"
        name="fundIds"
        value={fundIds}
        onChange={(e) => setFundIds(e.target.value)}
      />
      <ButtonWithHelp
        className={linkClass}
        onClick={() =>
          call('GenFundDaily', { d0, seq: false, fundIds }, '당일자 기준가생성')
        }
        helpid="당일자기준가생성"
        label="(당일자생성)"
      />
      <ButtonWithHelp
        className={linkClass}
        onClick={() =>
          call('GenFundDaily', { d0, seq: true, fundIds }, '연속 기준가생성')
        }
        helpid="연속기준가생성"
        label="(연속생성)"
      />
      <hr className="narrow light" />
      <b>첫화면</b>&nbsp;&nbsp;
      {assetDailyMenu.map((v) => getButton(v))}
      <hr className="narrow light" />
      {warnResMsg && (
        <div className="alert alert-slim alert-warning like-pre">
          {warnResMsg}
        </div>
      )}{' '}
      <UrlGrid
        args={gridArgs}
        params={getParams(gridArgs.url)}
        refreshNeeded={refreshNeeded}
      />
      <FileUploadDialog
        headerTitle={fileUploadTitle}
        fileExtensionLimit={['csv', 'txt']}
        fileSizeLimit={10}
        params={getParams(fileUploadUrl)}
        url={fileUploadUrl}
        visible={fileUploadVisible}
        setVisible={setFileUploadVisible}
        setRefreshNeeded={setRefreshNeeded}
      />
    </div>
  );
}
