import React, { useEffect, useState } from 'react';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { Calendar, CalendarType } from '../../Tms/Tools';
import CalendarCore from '../Tools/CalendarCore';
import { callAxios, ValidData } from '../../tmsutil';

const currMenu = '/AI/Mezzanine';
type PageFunc = 'Calendar'

export default function Mezzanine() {
  const { msgBox: m, logger } = useMessageState();
  const [year, setYear] = useState<number | null>(null)
  const [month, setMonth] = useState<number | null>(null)
  const [itmes, setItems] = useState<Calendar[]>([]);

  const call = (
    func: PageFunc,
    params: unknown,
    isGet: boolean,
    whenInvalidMsg?: string | null,
    onSuccess?: (data: ValidData) => void,

  ) =>
    callAxios({
      m,
      logger,
      url: `${currMenu}/${func}`,
      whenInvalidMsg,
      params,
      onSuccess,
      isGet,
    });

  const getData = () => {
    if (year != null && month != null) {
      call('Calendar', { y: year, m: month, ty: CalendarType.Default }, true, null, (res: Calendar[]) => { setItems(res) });
    }
  }

  useEffect(() => {
    getData();
  }, [year, month])

  return (
    <div style={{ minWidth: '1200px' }}>
      <hr className="narrow light" />
      <CalendarCore ty={CalendarType.Default} currItem={null} items={itmes} setUCYear={setYear} setUCMonth={setMonth} />
    </div>
  );
}
