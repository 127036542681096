import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import Dialog from 'tmslib/src/ui/Dialog';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import NumericInput from 'tmslib/src/ui/NumericInput';
import Button from 'tmslib/src/ui/Button';
import MultiCheckBox from 'tmslib/src/ui/MultiCheckBox';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import DateSelector from '../../shared/DateSelector';
import { callAxios, callAxiosGet } from '../../tmsutil';
import './roombook.css';

interface Book {
  Id: number;
  d: string;
  floor: number;
  roomNo: number;
  t0HHmm: string;
  t1HHmm: string;
  userNm: string | null;
  bookNm: string | null;
  attendees: number;
  department: string | null;
  subject: string | null;
  intAttendees: string | null;
  extAttendees: string | null;
  repeatDays: number[] | null;
  repeatEndDate: string | null;
}

type BookState = {
  Id: string; // 시간(HH:mm) 정보 | 방 정보
} & { [key: string]: Book | null }; // key: roomNo

type Floor = 'F15' | 'F16';

type Room = { roomNo: number; name: string };

const rooms: { [key in Floor]: Room[] } = {
  F15: [
    { roomNo: 0, name: '컨퍼런스룸' },
    ...'ABCDEFGHIJ'
      .split('')
      .map((v, i) => ({ roomNo: i + 1, name: `회의실${v}` })),
  ],
  F16: [
    { roomNo: 0, name: '컨퍼런스룸' },
    ...'1234567'
      .split('')
      .map((v, i) => ({ roomNo: i + 1, name: `회의실${v}` })),
  ],
};

export default function RoomBookMain() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info, user } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const [books, setBooks] = useState<{ [key in Floor]: BookState[] }>({
    F15: [],
    F16: [],
  });

  const [currBook, setCurrBook] = useState<Book | null>(null);
  const [bookDate, setBookDate] = useState(d);
  const [bookFloor, setBookFloor] = useState<Floor>('F15');
  const [bookRoomNo, setBookRoomNo] = useState(0);
  const [bookT0, setBookT0] = useState('');
  const [bookT1, setBookT1] = useState('');
  const [repeatDays, setRepeatDays] = useState<string[]>([]);
  const [repeatEndDate, setRepeatEndDate] = useState<string | null>(null);
  const [bookNm, setBookNm] = useState('');
  const [attendees, setAttendees] = useState(1);
  const [department, setDepartment] = useState('');
  const [subject, setSubject] = useState('');
  const [intAttendees, setIntAttendees] = useState('');
  const [extAttendees, setExtAttendees] = useState('');
  const [showEditor, setShowEditor] = useState(false);

  const getData = () => {
    callAxiosGet({
      m,
      logger,
      url: '/Tools/RoomBook/RoomBook',
      params: { d },
      confirmMsg: '',
      onSuccess: (data) => setBooks(data),
    });
  };

  useEffect(() => getData(), [d]);

  const AddOrModBook = (bookId: number | null) => {
    if (repeatDays && repeatDays.length && !repeatEndDate) {
      m.alert('요일 반복 선택 시 반복 종료일 필수');
      return;
    }
    const func = bookId == null ? 'Add' : 'Modify';
    callAxios({
      m,
      logger,
      url: `/Tools/RoomBook/${func}RoomBook`,
      params: {
        id: bookId,
        d: bookDate,
        floor: Number(bookFloor.slice(1)),
        roomNo: bookRoomNo,
        t0: bookT0,
        t1: bookT1,
        bookNm,
        attendees,
        department,
        subject,
        intAttendees,
        extAttendees,
        repeatDays,
        repeatEndDate,
      },
      onSuccess: () => {
        setShowEditor(false);
        getData();
      },
    });
  };

  const DeleteBook = (bookId: number) => {
    callAxios({
      m,
      logger,
      url: '/Tools/RoomBook/DeleteRoomBook',
      params: { id: bookId },
      onSuccess: () => {
        setShowEditor(false);
        getData();
      },
    });
  };

  const floors: Floor[] = ['F15', 'F16'];

  const col1 = 'col-4';
  const col2 = 'col-8';

  const setCurrFloorAndRoomNo = (val: string) => {
    const [floor, roomNo] = val.split('_');
    setBookFloor(floor as Floor);
    setBookRoomNo(Number(roomNo));
  };

  const infoId = '수용가능';
  const times = books.F15.map((v) => v.Id).slice(1); // infoId 제외

  return (
    <div className="children-me-2">
      <DateSelector
        value={d}
        onChange={(date) => date !== d && setSearchParams({ d: date })}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a href="https://drive.google.com/file/d/19RF0AtD5b_GHbiXMtyXALFdsu3p8bu4O/view">
        회의실 위치
      </a>
      <hr className="narrow light" />
      <div className="alert alert-slim alert-info">
        요일 반복 예약 생성 시, 특정일의 예약이 기존 타 예약과 충돌하면 해당일은
        입력되지 않습니다. <br />
        요일 반복 예약 내역은 수정/삭제 시, 이후 관련 예약들이 일괄
        수정/삭제됩니다.
      </div>
      {floors.map((f) => (
        <div key={f}>
          <hr className="narrow light" />
          <b>{f}</b>
          <SimpleGrid
            data={books[f]}
            columns={[
              'Id',
              ..._.range(0, rooms[f].length).map(
                (i) => String(i) as keyof BookState,
              ),
            ]}
            headers={['Time', ...rooms[f].map((v) => v.name)]}
            args={{
              meta: {
                containerClass: 'booking',
                dftColWidth: 70,
                onCellClick: (c) => {
                  if (c.column.id === 'Id' || c.row.id === infoId) return;

                  setBookFloor(f);
                  setBookRoomNo(Number(c.column.id));
                  setBookT0(c.row.id);

                  const b = c.row.original[c.column.id] as Book | null;
                  setCurrBook(b);

                  setBookDate(b?.d ?? d);

                  if (b) {
                    setBookT1(b.t1HHmm);
                  } else {
                    const i = times.indexOf(c.row.id);
                    const t1 =
                      i >= 0 && i < times.length - 2
                        ? times[i + 2]
                        : times[times.length - 1];
                    setBookT1(t1);
                  }
                  setBookNm(b?.bookNm ?? b?.userNm ?? user?.Name ?? '');
                  setAttendees(b?.attendees ?? 1);
                  setDepartment(b?.department ?? '');
                  setSubject(b?.subject ?? '');
                  setIntAttendees(b?.intAttendees ?? '');
                  setExtAttendees(b?.extAttendees ?? '');
                  setRepeatDays(b?.repeatDays?.map((v) => String(v)) ?? []);
                  setRepeatEndDate(b?.repeatEndDate ?? null);

                  setShowEditor(true);
                },
              },
              dftStyler: (v, c, r) => {
                const cursor =
                  c === 'Id' || r.original.Id === infoId
                    ? undefined
                    : 'pointer';
                return {
                  textAlign: 'center',
                  backgroundColor: (v as unknown as Book)?.Id
                    ? '#ffc'
                    : undefined,
                  cursor,
                };
              },
              dftFormatter: (v, c, r) => {
                if (c === 'Id' || r.id === infoId) return v;
                const b = v as unknown as Book;
                return b == null
                  ? ''
                  : `${b.bookNm ?? b.userNm}/${b.attendees}명`;
              },
              dftRowSpanFn: (c, r0, r1) => {
                const id0 = (r0.original[c] as unknown as Book)?.Id;
                const id1 = (r1.original[c] as unknown as Book)?.Id;
                return c !== 'Id' && !!id0 && id0 === id1;
              },
            }}
          />
        </div>
      ))}
      <Dialog
        visible={showEditor}
        style={{ width: '350px', height: '500px' }}
        onHide={() => setShowEditor(false)}
        footer={
          <div className="children-me-2">
            <Button
              label="입력"
              icon="Plus"
              onClick={() => AddOrModBook(null)}
              disabled={!!currBook}
            />
            <Button
              label="수정"
              icon="Pencil"
              onClick={() => AddOrModBook(currBook?.Id ?? null)}
              disabled={!currBook}
            />
            <Button
              label="삭제"
              icon="X"
              onClick={() => DeleteBook(currBook?.Id ?? 0)}
              disabled={!currBook}
            />
          </div>
        }
      >
        <div className="container text-left children-mb-2">
          <div className="row">
            <div className={col1}>날짜</div>
            <div className={col2}>
              <DateSelector value={bookDate} onChange={(v) => setBookDate(v)} />
            </div>
          </div>
          <div className="row">
            <div className={col1}>회의실</div>
            <div className={col2}>
              <select
                value={`${bookFloor}_${bookRoomNo}`}
                onChange={(e) => setCurrFloorAndRoomNo(e.target.value)}
              >
                {floors.map((f) => (
                  <optgroup key={f} label={f}>
                    {rooms[f].map((r) => (
                      <option key={r.roomNo} value={`${f}_${r.roomNo}`}>
                        {r.name}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
              &nbsp;<span style={{ color: 'gray' }}>{bookFloor}</span>
            </div>
          </div>
          <div className="row">
            <div className={col1}>시간</div>
            <div className={col2}>
              <select
                value={bookT0}
                onChange={(e) => setBookT0(e.target.value)}
              >
                {times.map((v) => (
                  <option key={v}>{v}</option>
                ))}
              </select>
              ~
              <select
                value={bookT1}
                onChange={(e) => setBookT1(e.target.value)}
              >
                {times
                  .filter((v) => v > bookT0)
                  .map((v) => (
                    <option key={v}>{v}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className={col1}>요일 반복</div>
            <div className={`${col2} children-me-2`}>
              <MultiCheckBox
                name="repeatDay"
                options={['1', '2', '3', '4', '5']}
                selected={repeatDays}
                labels={['M', 'T', 'W', 'T', 'F']}
                onChangeSelected={(v) => setRepeatDays(v)}
              />
            </div>
          </div>
          <div className="row">
            <div className={col1}>반복 종료일</div>
            <div className={col2}>
              <DateSelector
                value={repeatEndDate ?? ''}
                onChange={(v) => setRepeatEndDate(v)}
              />
            </div>
          </div>
          <div className="row">
            <div className={col1}>예약자</div>
            <div className={col2}>
              <input
                type="text"
                value={bookNm}
                onChange={(e) => setBookNm(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className={col1}>참석인원수*</div>
            <div className={col2}>
              <NumericInput
                value={attendees}
                style={{ width: '80px' }}
                onChange={(v) => setAttendees(v ?? 1)}
              />
            </div>
          </div>
          <div className="row">
            <div className={col1}>부서</div>
            <div className={col2}>
              <input
                type="text"
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className={col1}>회의내용</div>
            <div className={col2}>
              <input
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className={col1}>내부 참석자</div>
            <div className={col2}>
              <textarea
                value={intAttendees}
                style={{ height: '50px', width: '100%' }}
                onChange={(e) => setIntAttendees(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className={col1}>외부 참석자</div>
            <div className={col2}>
              <textarea
                value={extAttendees}
                style={{ height: '50px', width: '100%' }}
                onChange={(e) => setExtAttendees(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
