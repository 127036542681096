import { z } from 'zod';
import { VhGrpTy } from './Tms';
import { OrdStState } from './Ord';
import { PosBookTy, posBookTySchema } from './Common';
import {
  zodNumber,
  zodPosiInt,
  zodDate,
  zodDateTime,
  zodPosiNumber,
  zodDateNullable,
  zodRange,
} from './ZodType';

export interface ConfirmInfo {
  Id: number;
  stId: string;
  prodId: string;
  prodNm: string | null;
  fee: number;
  removedT: string | null;
  info: string | null;
  state: OrdStState;
  oppShortStId: string | null;
}

export interface KeepWei {
  Id: number;
  vhId: string;
  stId: string;
  prodId: string;
  prodNm: string | null;
  wei: number;
  used: number;
  wei2: number;
}

export enum LoanReqSort {
  Ord = 'Ord',
  ProdId = 'ProdId',
  ProdNm = 'ProdNm',
  State = 'State',
  T = 'T',
}

export const GetLoanReqSortName = (ty: LoanReqSort) => {
  switch (ty) {
    case LoanReqSort.Ord:
      return '순번';
    case LoanReqSort.ProdId:
      return '종목코드';
    case LoanReqSort.ProdNm:
      return '종목명';
    case LoanReqSort.State:
      return '단계';
    case LoanReqSort.T:
      return '생성T';
    default:
      return 'Wrong LoanReqSort';
  }
};

export enum LoanReqState {
  삭제 = '삭제',
  생성 = '생성',
  요청 = '요청',
  PBS컨펌 = 'PBS컨펌',
  취소 = '취소',
  타임컨펌 = '타임컨펌',
  스왑세팅 = '스왑세팅',
  완료 = '완료',
}

export enum LoanReqAcks {
  Init = 'Init',
  Sent = 'Sent',
  Acpt = 'Acpt',
}

export enum LoanReqBrkType {
  Default = 'Default',
  LastCnfrm = 'LastCnfrm',
  Pool = 'Pool',
  OverPool = 'OverPool',
  Manual = 'Manual',
  Fail = 'Fail',
}

export enum LoanReqQuotes {
  비교중 = '비교중',
  비쌈 = '비쌈',
  타사문의 = '타사문의',
  확정 = '확정',
}

export interface LoanReq {
  Id: number;
  tgt: VhGrpTy;
  prodId: string;
  prodNm: string | null;
  stId: string | null;
  ord: number;
  needq: number;
  state: LoanReqState;
  brkId: string;
  book: PosBookTy;
  swap: boolean;
  availq: number | null;
  fee: number | null;
  tstr: string;
  availd: string | null;
  ack: LoanReqAcks;
  finalq: number;
  brkTy: LoanReqBrkType;
  quoteId: number | null;
  noQuote?: boolean;
  quote: LoanReqQuotes | null;
  operNm: string | null;
  error: string | null;
  isConfirmOK?: boolean | null;
  swapInsuf?: boolean;
  stInfo?: { st: string; acpt: boolean | null }[] | null;
  swapBrkFam?: string | null;
}

export enum LoanReqLogTy {
  단계 = '단계',
  증권사 = '증권사',
  스왑 = '스왑',
  가능수량 = '가능수량',
  요율 = '요율',
  가능일 = '가능일',
  필요수량 = '필요수량',
  요율ID = '요율ID',
  요율상태 = '요율상태',
  작업자 = '작업자',
  INFO = 'INFO',
}

export interface LoanReqLog {
  Id: number;
  reqId: number;
  tstr: string;
  ty: LoanReqLogTy;
  oldVal: string | null;
  newVal: string | null;
  info: string | null;
  userNm: string | null;
}

export interface LoanReqVh {
  Id: number;
  reqId: number;
  vhId: string;
  needq: number;
  availq: number | null;
  finalq: number;
  prodId: string | null;
  fee: number | null;
}

export enum LoanReqVhLogTy {
  가능수량,
  필요수량,
}

export interface LoanReqVhLog {
  Id: number;
  rvhId: number;
  tstr: string;
  ty: LoanReqVhLogTy;
  oldVal: string | null;
  newVal: string | null;
  userNm: string | null;
}

export interface LoanReqPrior {
  Id: number;
  d0: string;
  d1: string;
  swap: boolean;
  brkId: string;
  ord: number;
  skip: boolean;
  note: string | null;
}

export const loanReqPriorSchema = z.object({
  d0: zodDate,
  d1: zodDate,
  swap: z.coerce.boolean(),
  brkId: z.string(),
  ord: zodPosiInt(false),
  skip: z.coerce.boolean(),
  note: z.string().nullish(),
});

export interface LoanSum {
  Id: number;
  brkId: string;
  prodId: string;
  prodNm: string | null;
  cnt: number;
  amt: number;
  fee: number;
}

export interface SwapSet {
  Id: number;
  brkId: string;
  vhId: string;
  prodId: string;
  prodNm: string | null;
  availq: number | null;
  needq: number;
  finalq: number;
  fee: number | null;
}

export interface LoanState {
  Id: number;
  doQuote: boolean;
  feeCnfrmMin: number;
  feeEnforceMax: number;
}

export interface LoanFail {
  Id: number;
  brkId: string;
  prodId: string;
  swap: boolean;
}

export interface CashShortLimit {
  Id: number;
  prodId: string;
  vhIn?: string;
  vhEx?: string;
  rto: number;
  prodNm?: string;
}

export const cashShortLimitSchema = z.object({
  prodId: z.string(),
  prodNm: z.string().nullish().readonly(),
  vhIn: z.string().nullish(),
  vhEx: z.string().nullish(),
  rto: zodRange(0, 1),
});

export interface CashShortLimitQty {
  Id: number;
  prodId: string;
  prodNm: string;
  vhId: string;
  cash: number;
  swap: number;
  limitRto: number;
  vhRto: number;
  limitQty: number;
  limitAvail: number;
}

export enum LoanChTransTy {
  Regular = 'Regular',
  CorpAct = 'CorpAct',
  KeepOffset = 'KeepOffset',
  KeepAdj = 'KeepAdj',
}

export interface LoanCh {
  Id: number;
  d: string;
  vhId: string;
  book: PosBookTy;
  prodId: string;
  brkId: string;
  sgdQty: number;
  fee: number;
  stId?: string | null;
  trTy: LoanChTransTy;
  t: string;
  reqId?: number | null;
  rerated?: string | null;
  rerate?: number | null;
  mrgn?: number | null;
  pos: number;
  prodNm?: string | null;
  filld: string;
  brkNm?: string | null;
  vhKsd?: string | null;
  expd: string;
  swap: boolean;
}

export const loanChTransTySchema = z.nativeEnum(LoanChTransTy);
export const loanChSchema = z.object({
  d: zodDate,
  vhId: z.string(),
  book: posBookTySchema,
  prodId: z.string(),
  prodNm: z.string().nullish().readonly(),
  brkId: z.string(),
  sgdQty: zodNumber(false), // numericStringSchema(false),
  fee: zodPosiNumber(false),
  mrgn: zodNumber(true).readonly(), // numericStringSchema(true),
  stId: z.string().nullish(),
  trTy: loanChTransTySchema,
  t: zodDateTime,
  reqId: zodPosiInt(true).readonly(),
  rerate: zodPosiNumber(true),
  rerated: zodDateNullable,
});

export interface LoanPos {
  Id: number;
  d: string;
  vhId: string;
  book: PosBookTy;
  prodId: string;
  brkId: string;
  pos: number;
  avgfee: number;
  stId?: string | null;
  mrgn?: number | null;
  prodNm?: string | null;
  recall: boolean;
}

export interface LoanRdmptAvail {
  Id: number;
  vhId: string;
  prodId: string;
  pos: number;
  shortAvail: number;
  rdmptAvail: number;
  recallAvail: number;
  prodNm?: string | null;
}

export interface LoanPosCmprWithKsd {
  Id: number;
  vhId: string;
  prodId: string;
  prodNm?: string | null;
  brkId: string;
  tpos: number;
  tfee: number;
  kpos: number;
  kfee: number;
}

export interface LoanRdmptPrior {
  Id: number;
  d0: string;
  d1: string;
  prodId: string;
  brkId: string;
  recall: number;
  rdmpt: number;
  ord: number;
  memo?: string | null;
  prodNm?: string | null;
  toRecall: number;
}

export const loanRdmptPriorSchema = z.object({
  d0: zodDate,
  d1: zodDate,
  prodId: z.string(),
  prodNm: z.string().nullish().readonly(),
  brkId: z.string(),
  recall: zodPosiNumber(false), // numericStringSchema(false),
  rdmpt: z.coerce.number().readonly(),
  ord: zodPosiInt(false),
  memo: z.string().nullish(),
});

export interface LoanPool {
  Id: number;
  d: string;
  brkId: string;
  prodId: string;
  prodNm?: string | null;
  qty?: number | null;
  fee?: number | null;
  t: string;
  userId: string;
  delT?: string | null;
  updT: string;
  userNm?: string | null;
}

export interface LoanFeeCnfrm {
  Id: number;
  d: string;
  prodId: string;
  stId: string;
  acpt: boolean;
  fee: number;
  t: string; // DateTime
  operId: string;
  prodNm?: string | null;
  operNm?: string | null;
}

export const loanFeeCnfrmSchema = z.object({
  prodId: z.string(),
  prodNm: z.string().nullish().readonly(),
  stId: z.string(),
  acpt: z.coerce.boolean(),
  fee: zodNumber(false),
  t: z.string().regex(/^\d{2}:\d{2}:\d{2}$/, 'Must be in HH:mm:ss format.'),
  operNm: z.string().nullish().readonly(),
});

export interface LoanFeeCheck {
  Id: number;
  vhId: string;
  prodId: string;
  prodNm: string;
  brkId: string;
  avgfee: number;
  pool: number;
  diff: number;
}

export enum LoanOfferState {
  Initiated = 'Initiated',
  Booked = 'Booked',
  Recalled = 'Recalled',
}

export enum LoanOfferChTransTy {
  Regular = 'Regular',
  CorpAct = 'CorpAct',
}

export interface LoanOfferCh {
  Id: number;
  d: string;
  vhId: string;
  brkId: string;
  prodId: string;
  state: LoanOfferState;
  sgdQty: number;
  fee: number;
  trTy: LoanOfferChTransTy;
  t: string;
  prodNm?: string | null;
}

export const loanOfferChTransTySchema = z.nativeEnum(LoanOfferChTransTy);

export const loanOfferStateSchema = z.nativeEnum(LoanOfferState);

export const loanOfferChSchema = z.object({
  vhId: z.string(),
  prodId: z.string(),
  prodNm: z.string().nullish().readonly(),
  brkId: z.string(),
  state: loanOfferStateSchema,
  sgdQty: zodNumber(false), // 음수, long
  fee: zodNumber(false),
  trTy: loanOfferChTransTySchema,
  t: zodDateTime,
});

export interface LoanOfferPos {
  Id: number;
  d: string;
  vhId: string;
  brkId: string;
  prodId: string;
  state: LoanOfferState;
  pos: number;
  avgfee: number;
  prodNm?: string | null;
  info?: string | null;
}

export const loanOfferPosSchema = z.object({
  d: zodDate,
  vhId: z.string(),
  brkId: z.string(),
  prodId: z.string(),
  state: loanOfferStateSchema,
  pos: zodNumber(false),
  avgfee: zodNumber(false),
  prodNm: z.string().nullish().readonly(),
  info: z.string().nullish(),
});

export enum LoanOfferReqState {
  삭제 = '삭제',
  생성 = '생성',
  승인대기 = '승인대기',
  접수대기 = '접수대기',
  처리중 = '처리중',
  처리불가 = '처리불가',
  예탁원완료 = '예탁원완료',
  완료 = '완료',
}

export interface LoanOfferReq {
  Id: number;
  d: string;
  vhId: string;
  brkId: string;
  prodId: string;
  sgdQty: number;
  fee: number;
  t: string;
  state: LoanOfferReqState;
  genId: string;
  mngrId: string | null;
  operId: string | null;
  beneVhId: string | null;
  note: string | null;
  availd: string | null;
  ty: string | null;
  prodNm: string | null;
  brkNm: string | null;
  genNm: string | null;
  mngrNm: string | null;
  operNm: string | null;
  toHoldRto: number | null;
}

export enum LoanOfferReqLogType {
  단계,
  메모,
}

export interface LoanOfferReqLog {
  Id: number;
  reqId: number;
  tstr: string;
  ty: LoanOfferReqLogType;
  oldVal: string | null;
  newVal: string | null;
  userId: string;
  info: string | null;
  userNm: string | null;
}

export interface RecallNeeded {
  Id: number;
  d: string;
  vhId: string;
  prodId: string;
  qty: number;
  t: string;
  prodNm: string | null;
  brkId: string | null;
  negQty: number;
}

export interface LoanOfferRatio {
  Id: number;
  vhId: string;
  loSum: number;
  posSum: number;
  wei: number;
}
