import React, { useEffect, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { IId } from './DataGrid';
import SimpleGrid, { SimpleGridArgs } from './SimpleGrid';
import OutsideHandler from '../ui/OutsideHandler';
import Dialog from '../ui/Dialog';

export default function SimpleGridDialog<T extends IId>({
  data,
  columns,
  headers,
  args,
}: {
  data: T[] | null;
  columns: (keyof T)[] | ColumnDef<T, unknown>[];
  headers?: string[];
  args?: SimpleGridArgs<T>;
}) {
  const [visible, setVisible] = useState(data != null);
  useEffect(() => {
    setVisible(data != null);
  }, [data]);

  return (
    <Dialog visible={visible} onHide={() => setVisible(false)}>
      <OutsideHandler callback={() => setVisible(false)}>
        {data && (
          <SimpleGrid
            data={data}
            columns={columns}
            headers={headers}
            args={args}
          />
        )}
      </OutsideHandler>
    </Dialog>
  );
}
