import { z } from 'zod';
import { Exchange } from './Prod';
import { BoFillTy, boFillTySchema, PosRstrTy, posRstrTySchema } from './BO';
import {
  zodNumber,
  zodPosiInt,
  zodPosiNumber,
  zodDate,
  zodDateNullable,
} from './ZodType';

enum CompLoc {
  국내설립법인 = '국내설립법인',
  해외현지법인 = '해외현지법인',
  외국법인 = '외국법인',
}
const compLocSchema = z.nativeEnum(CompLoc);

export interface AiComp {
  Id: string;
  nm: string;
  loc: CompLoc | null;
  cmpNo: string | null;
  regNo: string | null;
  ceo: string | null;
  sec0: string | null;
  sec1: string | null;
  sec2: string | null;
  ipoComp: string | null;
  ipoState: string | null;
  mainBiz: string | null;
  commStks: number | null;
  pfrdStks: number | null;
  stkOpts: number | null;
  guy0: string | null;
  tel0: string | null;
  mail0: string | null;
  guy1: string | null;
  tel1: string | null;
  mail1: string | null;
  qry: string | null;
  exch: Exchange | null;
  exchStr: string;
  mc: number | null;
  vent_d1: string | null;
}

// 대분류, 중분류, 소분류(미사용)에 대해,
// AiCode 테이블에 입력한게 콤보박스로 나오게 해달라고 했지만
// 잘 안바뀔 값이라 굳이 다이나믹하게 안하고, zod enum으로 처리
const sec0Schema = z.enum([
  '바이오',
  '산업재',
  '소비재',
  '소재/에너지',
  '금융',
  '엔터',
  '자동차',
  'IT',
  '기타',
]);

const sec1Schema = z.enum([
  '자동차',
  '반도체/디스플레이',
  '제약바이오',
  '의료기기/헬스케어',
  '에너지/화학',
  'IT소프트웨어',
  'IT부품',
  'IT장비',
  '화장품/건강기능식품',
  '게임',
  '건설/철강',
  '기계장비',
  '금융/보험',
  '운송/물류',
  '조선/해운',
  '항공/우주',
  '유통',
  '통신',
  '숙박/레저',
  '섬유/의류',
  '문화/컨텐츠',
  '식음료',
  '농축산',
  '기타제조',
  '기타전기전자',
  '기타서비스',
  '기타',
]);

export const aiCompSchema = z.object({
  Id: z.string(),
  nm: z.string(),
  loc: compLocSchema.nullish(),
  cmpNo: z.string().nullish(),
  regNo: z.string().nullish(),
  ceo: z.string().nullish(),
  sec0: sec0Schema.nullish(),
  sec1: sec1Schema.nullish(),
  sec2: z.string().nullish(),
  ipoComp: z.string().nullish(),
  ipoState: z.string().nullish(),
  mainBiz: z.string().nullish(),
  commStks: zodPosiInt(true),
  pfrdStks: zodPosiInt(true),
  stkOpts: zodPosiInt(true),
  guy0: z.string().nullish(),
  tel0: z.string().nullish(),
  mail0: z.string().nullish(),
  guy1: z.string().nullish(),
  tel1: z.string().nullish(),
  mail1: z.string().nullish(),
});

export interface AiRfx {
  Id: number;
  prodId: string;
  d: string;
  prc: number | null;
  lb: number | null;
}

export const aiRfxSchema = z.object({
  Id: z.coerce.number(),
  prodId: z.string(),
  d: zodDate,
  prc: zodPosiNumber(true),
  lb: zodPosiNumber(true),
});

enum CP {
  Call = 'Call',
  Put = 'Put',
}
const cpSchema = z.nativeEnum(CP);

export interface AiOpt {
  Id: number;
  prodId: string;
  cp: CP;
  rto: number | null;
  xp: number | null;
  reqD0: string | null;
  reqD1: string | null;
  exerD: string | null;
}

export const aiOptSchema = z.object({
  Id: z.coerce.number(),
  prodId: z.string(),
  cp: cpSchema,
  rto: zodNumber(true),
  xp: zodPosiNumber(true),
  reqD0: zodDateNullable,
  reqD1: zodDateNullable,
  exerD: zodDateNullable,
});

export interface AiProdDiv {
  Id: number;
  d: string;
  prodId: string;
}

export const aiProdDivSchema = z.object({
  d: zodDate,
});

enum IntrTy {
  분기_복리 = '분기_복리',
  반기_복리 = '반기_복리',
  연_복리 = '연_복리',
  분기_단리 = '분기_단리',
  반기_단리 = '반기_단리',
  연_단리 = '연_단리',
  기간무관_고정 = '기간무관_고정',
}
const intrTySchema = z.nativeEnum(IntrTy);

export interface AiProd {
  Id: string;
  prodId: string;
  prodNm: string | null;
  compId: string;
  round: string | null;
  issueD: string | null;
  ltd: string | null;
  convD: string | null;
  couponRt: number | null;
  ytm: number | null;
  ytp: number | null;
  ytc: number | null;
  rfxPrcLmt: number | null;
  rfxBase: string | null;
  rfxUnitPrd: string | null;
  intrTy: IntrTy | null;
  spAgrr: boolean | null;
  rfxs: AiRfx[];
  puts: AiOpt[];
  calls: AiOpt[];
  divs: AiProdDiv[];
}

export const aiProdSchema = z.object({
  Id: z.string(),
  prodId: z.string(),
  prodNm: z.string().nullish(),
  compId: z.string(),
  round: z.string().nullish(),
  issueD: zodDateNullable,
  ltd: zodDateNullable,
  convD: zodDateNullable,
  couponRt: zodPosiNumber(true),
  ytm: zodPosiNumber(true),
  ytp: zodPosiNumber(true),
  ytc: zodPosiNumber(true),
  rfxPrcLmt: zodPosiNumber(true),
  rfxBase: z.string().nullish(),
  rfxUnitPrd: z.string().nullish(),
  intrTy: intrTySchema.nullish(),
  spAgrr: z.coerce.boolean().nullish(),
});

export enum AiTgt {
  AI = 'AI',
  Capital = 'Capital',
  PI = 'PI',
}

export const getAiTgtName = (ty: AiTgt) => {
  switch (ty) {
    case AiTgt.AI:
      return '대체본부';
    case AiTgt.Capital:
      return 'Capital';
    case AiTgt.PI:
      return '고유자산';
    default:
      return ty;
  }
};

const aiTgtSchema = z.nativeEnum(AiTgt);

enum OldNew {
  구주 = '구주',
  신주 = '신주',
}
const oldNewSchema = z.nativeEnum(OldNew);

enum Deposit {
  등록 = '등록',
  실물 = '실물',
  보호예수 = '보호예수',
}
const depositSchema = z.nativeEnum(Deposit);

enum AiPrinTy {
  자전 = '자전',
  투자 = '투자',
  청약 = '청약',
  전환 = '전환',
  단주 = '단주',
  차입 = '차입',
  기초 = '기초',
}
const aiPrinTySchema = z.nativeEnum(AiPrinTy);

export interface AiFill {
  Id: number;
  invId: number;
  d: string;
  vhId: string;
  prodId: string;
  rstr: PosRstrTy;
  isBuy: boolean;
  qty: number;
  prc: number;
  fee: number;
  tax: number;
  div: number;
  fx: number | null;
  stId: string;
  stlD: string | null;
  fillTy: string | null;
  ty: BoFillTy;
  prinTy: AiPrinTy | null;
  cal: boolean;
  stFillId: number | null;
  whFillId: number | null;
  hasStFillId: boolean;
  hasWhFillId: boolean;
  stDivId: number | null;
  whDivId: number | null;
  hasStDivId: boolean;
  hasWhDivId: boolean;
  prin: number;
  prodNm: string | null;
  sgn: number;
}

export const aiFillSchema = z.object({
  invId: z.coerce.number(),
  d: zodDate,
  vhId: z.string(),
  prodId: z.string(),
  rstr: posRstrTySchema,
  isBuy: z.coerce.boolean().nullish(),
  qty: zodPosiInt(false),
  prc: zodPosiNumber(false),
  fee: zodNumber(false),
  tax: zodNumber(false),
  div: zodNumber(false),
  fx: zodPosiNumber(true),
  stId: z.string(),
  stlD: z.string().nullish(),
  fillTy: z.string().nullish(),
  ty: boFillTySchema,
  prinTy: aiPrinTySchema.nullish(),
  cal: z.coerce.boolean().nullish(),
  hasStFillId: z.coerce.boolean().readonly(),
  hasWhFillId: z.coerce.boolean().readonly(),
  hasStDivId: z.coerce.boolean().readonly(),
  hasWhDivId: z.coerce.boolean().readonly(),
  prin: z.coerce.number().readonly(),
  prodNm: z.string().nullish().readonly(),
  sgn: z.coerce.number().readonly(),
});

export interface AiPnL {
  Id: number;
  invId: number | null;
  prodId: string | null;
  prodNm: string | null;
  vhId: string;
  fills: AiFill[];
  firstBuyD: string | null;
  lastSellD: string | null;
  lastSellFillTy: string | null;
  conv: string | null;
  ourGuy: string | null;
  otherInvs: string | null;
  oiq: number;
  oia: number;
  oip: number;
  ocq: number;
  oca: number;
  ocp: number;
  ucq: number;
  uca: number;
  ucp: number;
  op: number;
  up: number;
  oa: number;
  ua: number;
  cltdAmt: number;
  cltdPrin: number;
  cltdPrft: number;
  cltdRt: number;
  cltdRto: number;
}

export interface AiInv {
  Id: number;
  prodId: string;
  ord: number;
  tgt: AiTgt;
  oldNew: OldNew | null;
  invD: string | null;
  convD: string | null;
  putD0: string | null;
  putDN: string | null;
  putD1: string | null;
  callD0: string | null;
  callD1: string | null;
  ltd: string | null;
  unlockD: string | null;
  deposit: Deposit | null;
  otherInvs: string | null;
  detProdId: string | null;
  mktExitD: string | null;
  emerExitD: string | null;
  emerExitWay: string | null;
  normExitD: string | null;
  normExitWay: string | null;
  dealComp: string | null;
  dealGuy: string | null;
  ourGuy: string | null;
  backupGuy: string | null;
  prod: AiProd;
  prodNm: string | null;
  invTy: string | null;
  holdTy: string | null;
  ytm: number | null;
  ytp: number | null;
  ytc: number | null;
  callRto: number | null;
  underNm: string | null;
  fills: AiFill[];
  pnls: AiPnL[];
  oia: number | null;
  oca: number | null;
  uca: number | null;
  prin: number | null;
  amt: number | null;
  rt: number | null;
  avgPrc: number | null;
  currPrc: number | null;
  prcDiffRto: number | null;
  cltdRt: number | null;
  note: string | null;
}

export const aiInvSchema = z.object({
  prodId: z.string(),
  ord: zodPosiInt(false),
  tgt: aiTgtSchema,
  oldNew: oldNewSchema.nullish(),
  invD: z.string().nullish(),
  convD: z.string().nullish(),
  putD0: z.string().nullish(),
  putDN: z.string().nullish(),
  callD0: z.string().nullish(),
  callD1: z.string().nullish(),
  ltd: z.string().nullish(),
  unlockD: z.string().nullish(),
  deposit: depositSchema.nullish(),
  otherInvs: z.string().nullish(),
  detProdId: z.string().nullish(),
  mktExitD: z.string().nullish(),
  emerExitD: z.string().nullish(),
  emerExitWay: z.string().nullish(),
  normExitD: z.string().nullish(),
  normExitWay: z.string().nullish(),
  dealComp: z.string().nullish(),
  dealGuy: z.string().nullish(),
  ourGuy: z.string().nullish(),
  backupGuy: z.string().nullish(),
  prodNm: z.string().nullish().readonly(),
  invTy: z.string().nullish(),
  holdTy: z.string().nullish(),
  ytm: zodNumber(true),
  ytp: zodNumber(true),
  ytc: zodNumber(true),
  underNm: z.string().nullish(),
  oia: zodNumber(true),
  oca: zodNumber(true),
  uca: zodNumber(true),
  prin: zodNumber(true),
  amt: zodNumber(true),
  rt: zodNumber(true),
  avgPrc: zodNumber(true),
  currPrc: zodNumber(true),
  prcDiffRto: zodNumber(true),
  cltdRt: zodNumber(true),
  note: z.string().nullish(),
});

export enum AiCodeTy {
  Sec0 = 'Sec0',
  Sec1 = 'Sec1',
  Sec2 = 'Sec2',
  FillTy = 'FillTy',
}

export interface AiCode {
  Id: number;
  ty: AiCodeTy;
  cd: string;
}

export interface PeriodPerf {
  Id: number;
  invId: number;
  prodId: string;
  prodNm: string | null;
  vhId: string;
  oia: number;
  cltdAmt: number;
  cltdPrin: number;
  cltdPrft: number;
  ourGuy: string | null;
}
