import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Row } from '@tanstack/react-table';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import SimpleGrid, {
  SimpleGridArgs,
  SimpleGridDataType,
} from 'tmslib/src/table/SimpleGrid';
import { useAuthState } from '../Auth/AuthContext';
import DVhStSelector from '../../shared/DVhStSelector';
import { DftBtnStyle } from '../../AppTypes';
import { callAxiosGet } from '../../tmsutil';
import { LsPerf, LsMkt, LsStat, LsPos, CntrByCtry } from '../../Tms/Pf';
import PerfChartsExDlg, {
  PerfChartsExDlgArgs,
} from '../Fund/Portfolio/PerfChartsExDlg';

const page = '/Front/LsWeekly';
const pagePf = '/Fund/Portfolio';
type PageFunc = 'Charts' | 'GetComment' | 'GetDate' | 'FiscalPeriods';

const dftChtOpt: Highcharts.Options = {
  chart: {
    type: 'line',
    width: 340,
    height: 240,
    zooming: {
      type: 'x',
    },
  },
  title: { text: '' },
  xAxis: { type: 'datetime' },
  yAxis: { title: { text: null } },
  credits: { enabled: false },
  legend: { itemStyle: { fontSize: '10px' }, margin: 5 },
  plotOptions: {
    line: {
      marker: { enabled: false },
      shadow: false,
      animation: false,
      lineWidth: 1,
    },
  },
  accessibility: { enabled: false },
  navigation: {
    buttonOptions: {
      verticalAlign: 'bottom',
    },
  },
};

// nav_daily_chart
const chtOptDaily: Highcharts.Options = {
  ...dftChtOpt,
  colors: ['#f00', '#00f', '#0f0', '#cc0', '#0c0', '#0cf', '#55c', '#c5c'],
};

// sec_wei_chart
const chtOptSecWei: Highcharts.Options = {
  ...dftChtOpt,
  chart: { ...dftChtOpt.chart, type: 'column' },
  xAxis: { labels: { useHTML: false, style: { fontSize: '10px' }, step: 1 } },
  yAxis: [{ title: { text: null }, labels: { style: { fontSize: '10px' } } }],
  plotOptions: {
    column: {
      pointPadding: 0, // 0.2,
      borderWidth: 0,
    },
    series: {
      shadow: false,
      animation: false,
      dataGrouping: { enabled: false },
    },
  },
};

const dftChtMkSzWei: Highcharts.Options = {
  chart: {
    type: 'pie',
    width: 160,
    height: 160,
  },
  credits: { enabled: false },
  title: undefined,
  tooltip: {
    pointFormat: '{point.percentage:.1f}%',
  },
  plotOptions: {
    pie: {
      cursor: 'pointer',
      dataLabels: {
        format: '<b>{point.name}</b>: {point.percentage:.0f}',
        distance: -1,
        alignTo: 'plotEdges',
        style: { fontSize: '11px' },
      },
      size: '100%',
    },
  },
};

// prettier-ignore
const posColumns: (keyof LsPos)[] = ['ctry', 'tics', 'prodNm', 'rt', 'w1', 'wch', 'cntr']
// prettier-ignore
const posHeaders: string[] = ['국가', 'Sec', 'Name', 'Ret', 'Wei', 'Ch', '기여']
const posArgs: SimpleGridArgs<LsPos> = {
  widths: { Id: 30, tics: 30 },
  meta: {
    dftColWidth: 60,
    maxHeight: 10000,
  },
  // prettier-ignore
  headerGroups: [['', 1], ['종목', 3], ['비중/기여', 4]],
  titles: {
    // prettier-ignore
    prodNm: (v: SimpleGridDataType, r: Row<LsPos>) => `${r.original.prodId} ${r.original.prodNm}`,
  },
  dftFormatter: (v: SimpleGridDataType, c: keyof LsPos, r: Row<LsPos>) => {
    if (c === 'prodNm') return r.original.prodNm?.slice(0, 10);
    if (c === 'tics') return r.original.tics?.slice(0, 2);
    if (c === 'Id') return v?.toFixedWithComma(0);
    if (c === 'cntr') return v?.toFixedWithComma(2);
    return v?.toFixedWithComma(1);
  },
  dftStyler: (v, c) => {
    if (['wch', 'cntr', 'rt'].indexOf(c) >= 0) {
      return { color: v?.getSignColor() };
    }
    return null;
  },
};

type Periods = {
  Id: string;
  nm: string;
};

export default function LsWeekly() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const [d0, setD0] = useState(d);
  const vh = searchParams.get('vh') || info?.dftVh || '';
  const st =
    searchParams.get('st') ||
    (vh === info?.dftVh ? info?.dftSt || '' : 'Whole');
  const [lsPerf, setLsPerf] = useState<LsPerf[]>([]);
  const [mkt, setMkt] = useState<LsMkt[]>([]);
  const [stat, setStat] = useState<LsStat[]>([]);
  const [posL, setPosL] = useState<LsPos[]>([]);
  const [posS, setPosS] = useState<LsPos[]>([]);
  const [daily, setDaily] = useState<Highcharts.Options>(chtOptDaily);
  const [secWei, setSecWei] = useState<Highcharts.Options>(chtOptSecWei);
  const [mkL, setMkL] = useState<Highcharts.Options>(dftChtMkSzWei);
  const [mkS, setMkS] = useState<Highcharts.Options>(dftChtMkSzWei);
  const [szL, setSzL] = useState<Highcharts.Options>(dftChtMkSzWei);
  const [szS, setSzS] = useState<Highcharts.Options>(dftChtMkSzWei);
  const [cntrByCtry, setCntrByCtry] = useState<CntrByCtry[]>([]);
  const [navPrd, setNavPrd] = useState<string | null>(null); // return 값
  const [periods, setPeriods] = useState<Periods[]>([]); // 조회

  const setNavDailyData = (data: Highcharts.SeriesLineOptions[]) => {
    const series = [...data];
    if (!series.length) return;
    series[0].lineWidth = 2;
    for (let k = 2; k < series.length; k += 1) {
      series[k].visible = false;
    }
    setDaily((p) => ({
      ...p,
      series,
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSecWeiData = (data: any) => {
    if (!data.secs.length) return;
    setSecWei(
      (p) =>
        ({
          ...p,
          xAxis: [{ categories: data.secs }],
          series: [
            { name: data.lnm, color: 'red', data: data.lw },
            { name: data.snm, color: 'blue', data: data.sw },
          ],
        }) as Highcharts.Options,
    );
  };

  const periodsTemplate = (
    <select
      id="dcht_prd"
      value={navPrd || ''}
      style={{ float: 'right' }}
      onChange={(e) => setNavPrd(e.target.value)}
    >
      <option key={-1} value="" aria-label="nav_period" />
      {periods.map((v) => (
        <option key={v.Id} value={v.Id}>
          {v.nm}
        </option>
      ))}
    </select>
  );

  const [perfChartsExDlgArgs, setPerfChartsExDlg] =
    useState<PerfChartsExDlgArgs>({ visible: false, daily });

  const callGet = (funcNm: PageFunc, params: unknown) =>
    callAxiosGet({
      m,
      logger,
      url: funcNm === 'GetDate' ? `${page}/${funcNm}` : `${pagePf}/${funcNm}`,
      params,
      onError: () => { },
      onSuccess: (data) => {
        if (data) {
          switch (funcNm) {
            case 'GetDate':
              setD0(data);
              break;
            case 'Charts':
              setNavDailyData(data.navDaily);
              setSecWeiData(data.secWei);
              break;
            case 'FiscalPeriods':
              setPeriods(data as Periods[]);
              break;
            default:
              m.alert('함수명 없음');
              break;
          }
        }
      },
    });

  const getDataGrid = () => {
    const params = { d, d0, vh, st };
    callAxiosGet({
      m,
      logger,
      url: '/Front/LsWeekly/Data',
      params,
      // 기준가 데이터 없는 경우
      onError: () => { },
      onSuccess: (data) => {
        const lsPerf0 = [];
        lsPerf0.push(data.perf); // record(1) => array(1)
        setLsPerf(lsPerf0);
        const mkt0 = [];
        mkt0.push(data.mkt);
        setMkt(mkt0);
        const stat0 = [];
        stat0.push(data.stat);
        setStat(stat0);
        // Arrow function should not return assignment       no-return-assign
        data.posL.forEach((v: LsPos, i: number) =>
          Object.assign(v, { Id: i + 1 }),
        ); // 일련번호 Id 생성
        setPosL(data.posL);
        data.posS.forEach((v: LsPos, i: number) =>
          Object.assign(v, { Id: i + 1 }),
        ); // 일련번호 Id 생성
        setPosS(data.posS);

        setMkL({
          ...dftChtMkSzWei,
          series: [
            { name: 'mk', data: data.mkL } as Highcharts.SeriesPieOptions,
          ],
        });
        setMkS({
          ...dftChtMkSzWei,
          series: [
            { name: 'mk', data: data.mkS } as Highcharts.SeriesPieOptions,
          ],
        });
        setSzL({
          ...dftChtMkSzWei,
          series: [
            { name: 'sz', data: data.szL } as Highcharts.SeriesPieOptions,
          ],
        });
        setSzS({
          ...dftChtMkSzWei,
          series: [
            { name: 'sz', data: data.szS } as Highcharts.SeriesPieOptions,
          ],
        });
        setCntrByCtry(data.cntrByCtry)
      },
    });
  };

  const getData = () => {
    if (!d || !vh || !st) return;
    setNavPrd(null);
    getDataGrid();
    callGet('FiscalPeriods', { vh, st });
    // isEtf CorrDailyChart, StdDailyChart
    // prettier-ignore
    callGet('Charts', { d, vh, st, navPrd: null, stdPrd: null, isEtf: false });
  };

  useEffect(() => {
    callGet('GetDate', {});
  }, []);

  useEffect(() => {
    if (!d || !vh || !st) return;
    // prettier-ignore
    callGet('Charts', { d, vh, st, navPrd, stdPrd: null, isEtf: false });
  }, [navPrd]);

  useEffect(() => {
    getData();
  }, [d, d0, vh, st]);

  return (
    <>
      <div className="target children-me-2" style={{ minWidth: '1300px' }}>
        <DVhStSelector
          value={[d, vh, st]}
          onChange={(d_, vhId, stId) =>
            setSearchParams({
              d: d_,
              vh: vhId || '',
              st: stId || '',
            })
          }
          vh={{ vir: true }}
          st={{ whole: true, vir: true }}
          initDate0={d0}
          onChangeD0={(date0) => setD0(date0)}
        />
        <button type="button" className={DftBtnStyle} onClick={() => getData()}>
          Get
        </button>
      </div>

      <hr className="narrow light" />
      <div className="d-flex justify-content-start">
        <div className="col-6">
          <SimpleGrid
            data={lsPerf}
            // prettier-ignore
            columns={['nav', 'ch', 'rt', 'lex', 'sex', 'cost', 'lw', 'sw', 'netw']}
            // prettier-ignore
            headers={['NAV', 'Ch', '%', 'L.ex', 'S.ex', 'Cost', 'L.Wei', 'S.Wei', 'Net']}
            args={{
              widths: { nav: 70 },
              meta: { dftColWidth: 60 },
              dftFormatter: (v, c) => {
                if (['lw', 'sw', 'netw'].indexOf(c) >= 0) {
                  return v?.toFixedWithComma(1);
                }
                return v?.toFixedWithComma(2);
              },
              dftStyler: (v, c) => {
                const color = c !== 'nav' ? v?.getSignColor() : undefined;
                return { color };
              },
            }}
          />
        </div>
        <div className="col-6">
          <SimpleGrid
            data={mkt}
            // prettier-ignore
            columns={['Id', 'kspRt', 'ksqRt', 'sp5Rt', 'nsqRt', 'dowRt']}
            // prettier-ignore
            headers={['', 'KOSPI', 'KOSDAQ', 'SP500', 'NASDAQ', 'DJIA']}
            args={{
              widths: {},
              meta: { dftColWidth: 60 },
              dftStyler: (v) => {
                const color = v?.getSignColor();
                return { color };
              },
            }}
          />
        </div>
      </div>
      <hr className="narrow light" />
      <div className="row" style={{ minWidth: '1000px' }}>
        <div className="col">
          <SimpleGrid
            data={stat}
            // prettier-ignore
            columns={['rt', 'rtStd', 'pfStdL', 'pfStdS', 'pfStd', 'pfStdL2', 'pfStdS2', 'pbrL', 'pbrS', 'chDom', 'chFrgn']}
            // prettier-ignore
            headers={['Ret', 'Std', 'L', 'S', 'Net', 'L', 'S', 'L', 'S', '국내', '해외']}
            args={{
              widths: {},
              meta: { dftColWidth: 60 },
              // prettier-ignore
              headerGroups: [
                ['수익률 1Y(Real)', 2], ['포트 Std(%)', 3], ['시장/현금제외', 2], ['PBR', 2], ['국내/해외', 2],
              ],
              dftStyler: (v, c) => {
                let color: string | undefined;
                if (['rt', 'chDom', 'chFrgn'].indexOf(c) >= 0) {
                  color = v?.getSignColor();
                }
                return { color };
              },
            }}
          />
        </div>
        <hr className="narrow light" />
        {/* 챠트 */}
        <div className="row" style={{ width: '1080px' }}>
          <div className="col-4" style={{ zIndex: 3 }}>
            <div style={{ padding: '10px' }}>
              <span style={{ fontWeight: 'bold' }}>Daily Chart</span>
              <span
                role="presentation"
                id="navDailyChart_ex"
                style={{ cursor: 'pointer', color: 'gray' }}
                onClick={() => setPerfChartsExDlg({ visible: true, daily })}
              >
                (확대)
              </span>
              {periodsTemplate}
            </div>
            <HighchartsReact highcharts={Highcharts} options={daily} />
          </div>
          <div className="col-4" style={{ zIndex: 3 }}>
            <div style={{ padding: '10px' }}>
              <span style={{ fontWeight: 'bold' }}>Sector Weight</span>
            </div>
            <HighchartsReact highcharts={Highcharts} options={secWei} />
          </div>
          <div className="col-4">
            <SimpleGrid
              data={cntrByCtry}
              columns={['ctry', 'cntrL', 'cntrS', 'cntrN']}
              headers={['국가', 'L', 'S', 'N']}
              args={{ widths: { ctry: 100, cntrL: 80, cntrS: 80, cntrN: 80 } }}
            />
          </div>

          <div className="col-4">
            <div className="row">
              <div className="col-6" style={{ zIndex: 5 }}>
                <HighchartsReact highcharts={Highcharts} options={mkL} />
              </div>
              <div className="col-6" style={{ zIndex: 5 }}>
                <HighchartsReact highcharts={Highcharts} options={mkS} />
              </div>
            </div>
            <div className="row">
              <div className="col-6" style={{ zIndex: 3 }}>
                <HighchartsReact highcharts={Highcharts} options={szL} />
              </div>
              <div className="col-6" style={{ zIndex: 3 }}>
                <HighchartsReact highcharts={Highcharts} options={szS} />
              </div>

            </div>

          </div>

          <div className="col-3" style={{ width: 0, height: 0, zIndex: 3 }}>
            <PerfChartsExDlg
              args={perfChartsExDlgArgs}
              setArgs={setPerfChartsExDlg}
            />
          </div>
        </div>
        {/* 챠트 끝 */}

        {/* pos */}
        <div className="row" style={{ width: '1200px' }}>
          <div className="col-6">
            <SimpleGrid
              data={posL}
              columns={['Id', ...posColumns]}
              headers={['L', ...posHeaders]}
              args={posArgs}
            />
          </div>
          <div className="col-6">
            <SimpleGrid
              data={posS}
              columns={['Id', ...posColumns]}
              headers={['S', ...posHeaders]}
              args={posArgs}
            />
          </div>

        </div>
        {/* pos end */}
      </div>
    </>
  );
}
