import React, { useEffect, useState } from 'react';
import { TableMeta, ColumnDef } from '@tanstack/react-table';
import _ from 'lodash';
import DataGrid, { IId, CellMeta, CellMetaDicType } from './DataGrid';
import { getNewRowId } from './datagridutil';
import { ColumnArgs, SchemaType, getColumnDefs, isColDef } from './SimpleGrid';

export const getCellValidationMeta = <T extends IId>(
  schema: SchemaType,
  items: T[],
): CellMetaDicType<T> =>
  // 각 item에 대해, 에러 없으면 {}라도 반환해야 에러 픽스 시 반영 가능
  _(items)
    .keyBy((v) => v.Id?.toString())
    .mapValues((v) => {
      const rowMeta = {} as { [key in keyof Partial<T>]: CellMeta };
      const parseRes = schema.safeParse(v);
      console.log(parseRes)
      if (!parseRes.success) {
        parseRes.error.issues.forEach((issue) => {
          const f = issue.path[0] as keyof T;
          if (f === 'Id') return;
          rowMeta[f] = {
            className: 'invalid',
            title: issue.message,
          };
        });
      }
      return rowMeta;
    })
    .value();

export type InputGridArgs<T extends IId> = ColumnArgs<T> & {
  meta?: TableMeta<T>;
  resetNeeded?: number;
  onSetData?: (data: T[]) => void;
  onSetCellMetas?: (metas: CellMetaDicType<T>) => void;
};

export default function InputGrid<T extends IId>({
  columns,
  headers,
  args,
}: {
  columns: (keyof T)[] | ColumnDef<T, unknown>[];
  headers?: string[];
  args?: InputGridArgs<T>;
}) {
  const { meta, resetNeeded, onSetData, onSetCellMetas, ...colArgs0 } =
    args ?? {};
  const colArgs = headers ? { ...colArgs0, headers } : colArgs0;
  const { schema } = colArgs;

  type TExt = T & { isExtraRow?: boolean };
  const initData = [
    { Id: getNewRowId([], meta?.idType), isExtraRow: true } as TExt,
  ]; // 빈줄 하나 추가
  const [data, setData] = useState<T[]>(initData);
  const [cellMetas, setCellMetas] = useState<CellMetaDicType<T>>({});
  useEffect(() => {
    setData(initData);
    setCellMetas({});
  }, [resetNeeded]);

  const colDef = isColDef(columns);

  // 일단 문자열 Id만 디스플레이 전제로 Id열 있으면 문자열 Id인걸로
  // eslint-disable-next-line react/destructuring-assignment  , @typescript-eslint/no-explicit-any
  const useStringId = !colDef && columns.contains('Id' as any);
  // eslint-disable-next-line react/destructuring-assignment
  const columnsToDisp = colDef
    ? (columns.map((v) => (v as ColumnDef<T, unknown>).id) as (keyof T)[])
    : (columns.map((v) => (v === 'Id' ? '_stringId' : v)) as (keyof T)[]);

  useEffect(() => {
    // console.log(data);
    let toSend: T[] = data.filter((v) => !(v as TExt).isExtraRow);
    if (useStringId) {
      toSend = toSend.map((v) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { Id: unusedTmpVar, _stringId, ...rest } = v;
        return { ...rest, Id: _stringId } as T;
      });
    }
    onSetData?.(toSend);
  }, [data]);

  useEffect(() => onSetCellMetas?.(cellMetas), [cellMetas]);

  const initObj = _(columnsToDisp)
    .keyBy()
    .mapValues(() => null)
    .value();
  const cols = colDef
    ? (columns as ColumnDef<T, unknown>[])
    : getColumnDefs(columnsToDisp, colArgs);
  const appendExtraRow = (newData: T[]) => {
    if (!newData.some((v) => (v as TExt).isExtraRow)) {
      const extra = {
        Id: getNewRowId(newData, meta?.idType),
        isExtraRow: true,
      };
      newData.push(extra as TExt);
    }
  };
  const tableMeta: TableMeta<T> = {
    ...meta,
    editable: true,
    rowAppendable: true,
    areaPastable: true,
    areaClearable: true,
    rowDeletable: true,
    editOnKeyDown: true,
    useGlobalFilter: false,
    useFilterBox: false,
    updateField: (original, rowIdx, colId, value) => {
      if (colId === 'Id') throw new Error('Id 수정 불가');

      const newData = data.map((v) => {
        if (v.Id !== original.Id) return v;
        const updated = { ...v, [colId]: value };
        (updated as TExt).isExtraRow =
          !value &&
          Object.entries(updated).every(
            ([kk, vv]) => kk === 'Id' || kk === 'isExtraRow' || !vv,
          );
        return updated;
      });
      appendExtraRow(newData);
      setData(newData);

      if (schema) {
        const metas = getCellValidationMeta(
          schema,
          newData.filter((v) => !(v as TExt).isExtraRow),
        );
        setCellMetas(metas); // 기존꺼 replace 해야
      }
    },
    updateData: (added, updated, removed) => {
      const newData = data
        .filter((v) => !removed.has(v.Id))
        .map((v) =>
          Object.prototype.hasOwnProperty.call(updated, v.Id)
            ? { ...initObj, ...v, ...updated[v.Id], isExtraRow: undefined }
            : v,
        )
        .concat(added);

      appendExtraRow(newData);
      setData(newData);

      if (schema) {
        const editedIds = new Set(
          added
            .filter((v) => !(v as TExt).isExtraRow)
            .map((v) => String(v.Id))
            .concat(
              Object.entries(updated).map(
                ([k, v]) => (v as IId)?.Id?.toString() ?? k,
              ),
            ),
        );
        const edited = newData.filter((v) => editedIds.has(String(v.Id)));
        const metas = getCellValidationMeta(schema, edited);
        setCellMetas(metas); // 기존꺼 replace 해야
      }
    },
  };
  return (
    <DataGrid
      data={data}
      columns={cols}
      meta={tableMeta}
      cellMetas={cellMetas}
    />
  );
}
