import React, { useEffect, useState } from 'react';
import TabView, { Tab } from 'tmslib/src/ui/TabView';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { simpleTable } from 'tmslib/src/table/tables';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import SmallBadge from 'tmslib/src/ui/SmallBadge';
import { OrdStPrd } from '../../../Tms/Ord';
import { LoanOfferReq } from '../../../Tms/Loan';
import OrdStPrdTab from './OrdStPrdTab';
import LoanOfferReqTab from './LoanOfferReqTab';
import { VhGrpTy } from '../../../Tms/Tms';
import { useAuthState } from '../../Auth/AuthContext';
import { FuncCall, callAxiosGet } from '../../../tmsutil';

interface Props {
  tgtCall: FuncCall;
  d: string;
  stId: string | null;
  vhGrp: VhGrpTy | null;
  ostPrds: OrdStPrd[];
  loanOfferReqs: LoanOfferReq[];
}

interface FundSz {
  vh: string;
  st: number;
  wh: number;
}

interface FutSz {
  prodId: string;
  prodNm: string;
  wvh1: number | null;
  wvh2: number | null;
  wvh3: number | null;
}

export default function ExtraTabs({
  tgtCall,
  d,
  stId,
  vhGrp,
  ostPrds,
  loanOfferReqs,
}: Props) {
  const [fundSz, setFundSz] = useState<FundSz[]>([]);
  const [futSz, setFutSz] = useState<FutSz[]>([]);
  const [reprVhs, setReprVhs] = useState<string[]>([]);
  const [mrgnRto, setMrgnRto] = useState<{ astNm: string; rto: number }[]>([]);

  const [chtNavAvgOpts, setChtNavAvgOpts] = useState<Highcharts.Options>({
    chart: { type: 'line', width: 550, height: 300 },
    credits: { enabled: false },
    title: {
      text: '5일 기준가 & 표준편차',
      margin: 0,
      style: { fontSize: '14px' },
    },
    xAxis: { type: 'datetime' },
    yAxis: [
      { title: { text: '기준가' } },
      { title: { text: '표준편차(%)' }, opposite: true },
    ],
    legend: { itemStyle: { fontSize: '10px' }, margin: 5 },
    plotOptions: {
      line: {
        marker: { enabled: false },
        shadow: false,
        animation: false,
        lineWidth: 2,
      },
    },
    accessibility: { enabled: false },
  });

  const [chtTuOpts, setChtTuOpts] = useState<Highcharts.Options>({
    chart: { type: 'line', width: 500, height: 300 },
    credits: { enabled: false },
    title: {
      text: '20일 평균 연환산 회전율(%)',
      margin: 0,
      style: { fontSize: '14px' },
    },
    xAxis: { type: 'datetime' },
    yAxis: { title: { text: null } },
    legend: { enabled: false },
    plotOptions: {
      line: {
        marker: { enabled: false },
        shadow: false,
        animation: false,
        lineWidth: 2,
      },
    },
    accessibility: { enabled: false },
  });

  const { user } = useAuthState();
  const { msgBox: m, logger } = useMessageState();

  useEffect(() => {
    if (!stId || !vhGrp) return;
    const params = { d, st: stId, tgt: vhGrp };
    callAxiosGet({
      m,
      logger,
      url: '/Front/Target/EtcData',
      params,
      onSuccess: (data) => {
        setFundSz(data.fundSize);
        setFutSz(data.futSz);
        setReprVhs(data.reprVhs);
        setMrgnRto(data.astMrgnRto);

        setChtNavAvgOpts((p) => ({
          ...p,
          series: [
            { ...data.navAvg?.[0], name: '5일 기준가' },
            { ...data.navAvg?.[1], yAxis: 0 },
            { ...data.navAvg?.[2], yAxis: 1 },
          ],
        }));

        setChtTuOpts((p) => ({
          ...p,
          series: [{ data: data.turnOver, name: '회전율(%)', type: 'line' }],
        }));
      },
    });
  }, [d, stId, vhGrp]);

  const futSzCols = ['prodId', 'prodNm'].concat(
    vhGrp === VhGrpTy.HFund ? ['wvh1', 'wvh2', 'wvh3'] : ['wvh1'],
  ) as (keyof FutSz)[];
  const futSzColNms = ['Code', 'Name'].concat(reprVhs);

  const nOstPrds = ostPrds.length ? ostPrds.length.toString() : undefined;
  const nLoanOfferReqs = loanOfferReqs.length
    ? loanOfferReqs.length.toString()
    : undefined;
  const tabs: Tab[] = [
    {
      header: (
        <span>
          기간 주문<SmallBadge>{nOstPrds}</SmallBadge>
        </span>
      ),
      content: <OrdStPrdTab call={tgtCall} d={d} data={ostPrds} />,
    },
    {
      header: (
        <span>
          대여 승인<SmallBadge>{nLoanOfferReqs}</SmallBadge>
        </span>
      ),
      content: <LoanOfferReqTab call={tgtCall} data={loanOfferReqs} />,
    },
    {
      header: '5일기준가 & 표준편차',
      content: (
        <HighchartsReact highcharts={Highcharts} options={chtNavAvgOpts} />
      ),
    },
    {
      header: '회전율 차트',
      content: <HighchartsReact highcharts={Highcharts} options={chtTuOpts} />,
    },
    {
      header: '선물 계약%',
      content: simpleTable(futSz, futSzCols, { headers: futSzColNms }),
    },
    ...(user?.isAdvisor
      ? []
      : [
          {
            header: '펀드별 한도',
            content: simpleTable(fundSz, ['vh', 'st', 'wh'], {
              headers: ['Fund', '매니저', '전체'],
            }),
          },
          {
            header: '현금 소진율',
            content: simpleTable(mrgnRto, ['astNm', 'rto'], {
              headers: ['자산군', '소진율'],
            }),
          },
        ]),
  ];

  return <TabView tabs={tabs} />;
}
